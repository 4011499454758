import React from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { MyButton, MyInput, MyText, Title } from "../../components";
import { useAppDispatch } from "../../redux/hooks";
import { useForm } from "react-hook-form";
import { login } from "../../redux/thunks/auth";
import { AuthLoginDto } from "../../dto/auth";

interface ILoginFormValues {
  name?: string;
  password?: string;
}

export const Login = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<ILoginFormValues>({ mode: "onChange" });

  const onSubmit = (data: ILoginFormValues) => {
    dispatch(login(data as AuthLoginDto));
  };

  return (
    <Box className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Title text="Login" />
          <Box mt={1}>
            <MyText text="Please Log In" />
          </Box>
        </Box>
        <Box className={classes.inputs}>
          <MyInput
            label="Benutzername"
            placeholder="Bitte ausfullen"
            control={control}
            name={"name"}
            error={errors.name}
            rules={{
              required: "Benutzername is required",
            }}
          />
          <MyInput
            type="password"
            rules={{
              required: "Passwort is required",
            }}
            label="Passwort"
            placeholder="Bitte ausfullen"
            control={control}
            name={"password"}
            error={errors.password}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <MyButton disabled={!isValid} text="Anmelden" type="submit" />
        </Box>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.paper,
    height: "94.7vh",
  },
  inputs: {
    marginTop: 36,
  },
  form: {
    background: "white",
    width: 668,
    padding: "84px 112px",
    borderRadius: 2,
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.12)",
  },
}));
