import { Box, Grid } from "@material-ui/core";
import { MyButton, MyFilePicker, MyInput, Preloader, Title } from "components";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useGetMediaCategoryDetailQuery, useGetTagsQuery, useUpdateMediaCategoryMutation } from "api";
import { useForm } from "react-hook-form";
import { useHandleFile } from "hooks";
import { Label } from "components/shared/Typography/Label";
import { checkFileExist } from "utils/checkFileExist";
import { MyTags } from "components/shared/Inputs/MyTags";
import { IDropdownOption } from "interfaces/IDropdownOption";
import { useNavigate } from "react-router";
import { IMAGE } from "constants/imagesConst";

export interface IMediaCategoryFormValues {
  name: string;
  tags?: any;
}

export const MediaCategoryDetail: React.FC = () => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<IMediaCategoryFormValues>({ mode: "onChange" });

  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTags, setCurrentTags] = useState<IDropdownOption[] | []>([]);

  const { data: category, isLoading: isGettingCategory } = useGetMediaCategoryDetailQuery(Number(id));
  const [editCategory, { isLoading: isEditingCategory }] = useUpdateMediaCategoryMutation();

  const { data: tags, isLoading: isGettingTags } = useGetTagsQuery();

  useEffect(() => {
    if (category) {
      setCurrentTags(category.tags ? category.tags : []);
      setValue("name", category.name);
      if (category.image) {
        handleChangeManagerImage(category.image);
      }
    }
  }, [category]);

  const { file: managerImage, handleChangeFile: handleChangeManagerImage } = useHandleFile();

  const saveInform = (data: IMediaCategoryFormValues) => {
    const formData = new FormData();

    if (category) {
      checkFileExist(managerImage, category.image, formData);
      formData.append("name", data.name);
      formData.append("id", String(category.id));

      formData.append(
        "tags",
        JSON.stringify(
          currentTags.map((ct) => {
            let tag;
            if (ct.isNew) {
              tag = tags?.find((tag) => tag.name === ct.name);
              if (tag) {
                return {
                  name: ct.name,
                  id: tag?.id,
                  media_page_categoryId: id,
                  isNew: false,
                };
              } else {
                return {
                  name: ct.name,
                  id: ct.id,
                  media_page_categoryId: id,
                  isNew: ct.isNew,
                };
              }
            } else {
              return {
                name: ct.name,
                id: ct.id,
                media_page_categoryId: id,
                isNew: ct.isNew,
              };
            }
          }),
        ),
      );
      editCategory(formData);
      navigate(`/dashboard/media`);
    }
  };

  const handleSelectTags = (newTag: IDropdownOption | null, isDelete?: boolean, isClear?: boolean) => {
    if (isClear) {
      setCurrentTags([]);
    } else {
      if (newTag) {
        setCurrentTags((prev) => {
          if (isDelete) {
            return prev.filter((i) => i.id !== newTag.id);
          } else {
            return [newTag, ...prev];
          }
        });
      }
    }
  };

  const handleEnterTags = (newTag: IDropdownOption) => {
    setCurrentTags((prev) => [...prev, newTag]);
  };

  return (
    <>
      <Box mb={8}>
        <Title text={`Category - ${category?.name}`} />
      </Box>
      <Grid container>
        <Grid container>
          <Grid xs={11} md={7} xl={3} item>
            <form onSubmit={handleSubmit(saveInform)}>
              <MyInput label="Name" placeholder="Name" control={control} name="name" rules={{ required: "Name is required" }} error={errors.name} />
              <Box mb={5}>
                <Label text="Icon" />
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <Box
                mb={3}
                style={{
                  position: "relative",
                  maxWidth: "400px",
                  minWidth: "300px",
                }}
              >
                <Label text="Tags" />
                <MyTags
                  updateValue={setValue}
                  handleSelectTags={handleSelectTags}
                  handleEnterTags={handleEnterTags}
                  name="tags"
                  control={control}
                  currentTags={currentTags ? currentTags : []}
                  options={tags ? tags : []}
                />
              </Box>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </form>
          </Grid>
        </Grid>
        <Preloader isLoading={isGettingCategory || isGettingTags || isEditingCategory} />
      </Grid>
    </>
  );
};
