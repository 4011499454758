import { useForm } from "react-hook-form";
import { MyButton, MyInput } from "../shared";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

interface IAddEditCategoryForm {
  action: (name: string) => void;
  isEdit?: boolean;
  category?: string;
  btnText?: string;
  placeholder?: string;
}

export const AddEditCategoryForm: React.FC<IAddEditCategoryForm> = ({ action, btnText, placeholder, isEdit, category }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<{ name: string }>({ mode: "onChange" });

  useEffect(() => {
    if (isEdit && category) {
      setValue("name", category);
    }
  }, [category]);

  const onSubmit = (data: { name: string }) => {
    action(data.name);
    reset({
      name: "",
    });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <MyInput nmb={true} placeholder={placeholder ? placeholder : "Category Name"} control={control} name="name" rules={{ required: `Category name is required` }} error={errors.name} />
      <MyButton disabled={!isValid} text={btnText ? btnText : isEdit ? "Save" : "Add Category"} type="submit" />
    </form>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: 59,
    "& button": {
      marginLeft: 20,
    },
  },
}));
