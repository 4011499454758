import React, { useEffect, useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { MyButton, MyCheckbox, MyDropdown, MyFilePicker, MyInput, Preloader, Title } from "components";
import { urlValidation } from "components/shared/Inputs/validations";
import { useHandleFile } from "hooks";
import { IMAGE } from "constants/imagesConst";
import { useCreatePushMessagesMutation } from "api/pushAPI";
interface IFormValues {
  title: string;
  text: string;
  url: string;
}

export const Push = () => {
  const classes = useStyles();

  const { file: image, handleChangeFile: handleChangeManagerImage } = useHandleFile();
  const [createEvent] = useCreatePushMessagesMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      title: "",
      text: "",
      url: "",
    },
  });

  const onSubmit = (data: any) => {
    const formData: any = new FormData();
    formData.append("image", image);
    formData.append("title", data.title);
    formData.append("text", data.text);
    formData.append("url", data.url);

    createEvent(formData).then(() => {
      reset({
        title: "",
        text: "",
        url: "",
      });
      handleChangeManagerImage(null);
    });
  };

  return (
    <>
      <Box mb={2}>
        <Title text="Push Messages" />
      </Box>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Box mb={5}>
          <Grid container>
            <Grid item xs={4}>
              <MyFilePicker file={image} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item container xs={4}>
            <Grid item xs={12}>
              <MyInput label="Title" placeholder="Event XY" control={control} name="title" rules={{ required: "Title is required" }} error={errors.title} />
            </Grid>
            <Grid item xs={12}>
              <MyInput label="Text" placeholder="" control={control} textarea name="text" minRows={8} rules={{ required: "Text is required" }} error={errors.text} />
            </Grid>
            <Grid item xs={12}>
              <MyInput label="URL" placeholder="URL" control={control} name="url" rules={urlValidation(false)} error={errors.url} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <MyButton disabled={false} width={150} text="Send now" type="submit" />
        </Grid>
      </form>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 30,
  },
}));
