import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_URLS } from "config";
import { getFromLocalStorage } from "../utils";
import { createPush } from "dto/push/createPush.dto";
import { ITokens } from "models";

export const eventsApi = createApi({
  reducerPath: "pushApi",
  tagTypes: ["PushMessages"],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URLS.push,
    prepareHeaders: (headers) => {
      const tokens = getFromLocalStorage("tokens") as ITokens;
      if (tokens?.accessToken) {
        headers.set("authorization", `Bearer ${tokens?.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    createPushMessages: build.mutation<createPush, createPush>({
      query: (body) => ({
        url: ``,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["PushMessages"],
    }),
  }),
});

export const { useCreatePushMessagesMutation } = eventsApi;
