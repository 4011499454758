import { Box, Grid } from "@material-ui/core";
import { AddEditCategoryForm, CategoryItem, DeleteModal, Preloader, Title } from "components";
import { useModalWindow } from "hooks";
import React, { useState } from "react";
import { useAddPaletteMutation, useChangeOrderPaletteMutation, useChangeStatusPaletteMutation, useDeletePaletteMutation, useGetPalettesQuery } from "api";
import { IPalette } from "models";
import { ChangeOrderPriceDto, ChangeStatusPriceDto } from "dto";

export const Palettes = () => {
  const [currentPaletteId, setCurrentPaletteId] = useState<number>();

  const { data, isLoading } = useGetPalettesQuery();
  const [addPalette] = useAddPaletteMutation();
  const [deletePalette] = useDeletePaletteMutation();
  const [changeStatus] = useChangeStatusPaletteMutation();
  const [changeOrder] = useChangeOrderPaletteMutation();

  const { open, openWindow, closeWindow } = useModalWindow();

  const handleAddPalette = (name: string) => {
    addPalette({ name });
  };
  // // eslint-disable-next-line no-debugger
  // debugger;
  const onDelete = () => {
    if (currentPaletteId) {
      deletePalette(currentPaletteId);
    }
  };
  const onChangeStatus = (data: ChangeStatusPriceDto) => {
    changeStatus(data);
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder(data);
  };

  if (isLoading) <Preloader isLoading={true} />;
  return (
    <>
      <Box mb={2}>
        <Title text="Category" />
      </Box>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          {data &&
            data.map((i: IPalette, index: number) => (
              <CategoryItem
                openWindow={openWindow}
                changeStatusAction={onChangeStatus}
                deleteAction={(id: number) => setCurrentPaletteId(id)}
                changeOrderAction={onChangeOrder}
                lastIndex={data.length - 1}
                id={i.id}
                index={index}
                key={i.id}
                name={i.name}
                isStatus={true}
                status={i.status}
                isArrows={true}
              />
            ))}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <AddEditCategoryForm action={handleAddPalette} />
        </Grid>
      </Grid>

      <DeleteModal model={"fund palette"} open={open} closeWindow={closeWindow} onOk={onDelete} />
    </>
  );
};
