import { Box, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import clsx from "clsx";

interface IArrowIcon {
  up?: boolean;
}

export const ArrowIcon: React.FC<IArrowIcon> = ({ up }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, up && classes.rotate)}>
      <ArrowDownwardIcon fontSize={"small"} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    background: "#E3E6EC",
    borderRadius: 2,
    marginLeft: 2,
    marginRight: 2,
    color: "grey",
    zIndex: 100,
    "&:hover": {
      background: theme.palette.text.secondary,
      color: "white",
      cursor: "pointer !important",
    },
  },
  rotate: {
    transform: "rotate(180deg)",
  },
}));
