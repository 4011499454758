import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import { useAppDispatch } from "redux/hooks";
import { setEventName } from "redux/slices/eventsSlice";
import { Title, MyButton, MyInput, EventItem } from "components";
import { useGetEventsQuery, useDelEventMutation, useChangeEventStatusMutation, useChangeOrderEventMutation } from "api";
import { IEvent } from "models";
import { ChangeOrderPriceDto } from "dto";

interface IFormValues {
  name: string;
}

const LIMIT = 20;

export const Events = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [page, setPage] = useState<number>(1);
  const [events, setEvents] = useState<IEvent[]>([]);
  const { data } = useGetEventsQuery({ page, limit: LIMIT });
  const [changeStatus] = useChangeEventStatusMutation();
  const [changeOrder, dataOrder] = useChangeOrderEventMutation();
  const [deleteEvent] = useDelEventMutation();

  const { handleSubmit, control } = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (data?.items && data?.items.length > 0) {
      setEvents([...events, ...data.items]);
    }
  }, [data]);

  const onChangeStatus = (id: number, status: boolean) => {
    changeStatus({
      id,
      status,
    }).then(() => {
      setEvents(
        events.map((e) => {
          if (e.id === id) {
            return {
              ...e,
              status,
            };
          }
          return e;
        }),
      );
    });
  };

  const onSubmit = (data: any): void => {
    dispatch(setEventName(data.name));
    navigate(`/dashboard/events/details`);
  };

  const handleDelete = (id: number) => {
    deleteEvent(id).then(() => {
      const eventsWithoutLastFetch = page > 1 ? events.slice(0, LIMIT * page - 1) : [];
      const filteredEvents = eventsWithoutLastFetch.filter((e) => e.id !== id);
      setEvents(filteredEvents);
    });
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder(data);

    const pickedEvent: any = events.find((item): any => item.id === data.mainId);
    const index = events.indexOf(pickedEvent);
    if (!dataOrder.isError && !dataOrder.isLoading) {
      if (data.direction == "next") {
        const leftIndex = index - 1;
        events.splice(leftIndex, 2, events[index], events[leftIndex]);
      } else {
        const leftIndex = index + 1;
        events.splice(index, 2, events[leftIndex], events[index]);
      }
    }
  };

  return (
    <>
      <Box mb={2}>
        <Title text="Events" />
      </Box>
      <Grid container>
        <Grid item xs={11} md={7} xl={7}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <MyInput label="Name" placeholder="Event name" control={control} name="name" />
            <MyButton text="Add Event" type="submit" />
          </form>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {events.map((e: IEvent, index: number) => (
            <EventItem
              id={e.id}
              headline={e.name}
              date={e.date}
              status={e.status}
              text={e.descriptionText}
              changeStatusAction={onChangeStatus}
              onDelete={() => handleDelete(e.id)}
              changeOrderAction={onChangeOrder}
              key={e.id}
              isArrows={true}
              index={index}
              lastIndex={events.length - 1}
            />
          ))}
          {page !== data?.meta.totalPages && (
            <Grid className={classes.loadMore}>
              <MyButton
                text="Load More"
                type="submit"
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    marginTop: 40,
    "& button": {
      marginLeft: 20,
    },
  },
  loadMore: {
    textAlign: "center",
    marginTop: "2rem",
  },
}));
