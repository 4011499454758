import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NewsState {
  type: string;
}

const initialState: NewsState = {
  type: "Video",
  //  TODO constats
};

export const newsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    news(state: NewsState, action: PayloadAction<string>) {
      state.type = action.payload;
    },
  },
});

const { actions } = newsSlice;
// Extract and export each action creator by name
export const { news } = actions;
