import { Box, makeStyles, Theme } from "@material-ui/core";
import { Layout } from "../../layouts/Layout";
import { Nav } from "../../layouts/Nav";
import React, { useEffect, useState } from "react";
import { useLogout } from "../../hooks";
import { MyBreadcrumbs } from "../../components/MyBreadcrumps";

interface IDashboard {
  component: React.ComponentElement<any, any>;
}

export const Dashboard: React.FC<IDashboard> = ({ component }) => {
  const classes = useStyles();

  const [startTime, setStartTime] = useState(+new Date());

  const [logout] = useLogout();

  useEffect(() => {
    document.addEventListener("click", () => setStartTime(+new Date()));
    document.addEventListener("mousemove", () => setStartTime(+new Date()));
  }, [document]);

  useEffect(() => {
    const interval = setInterval(checkForWarning, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  function checkForWarning() {
    const difference = +new Date() - startTime;
    if (difference > 6000000) {
      logout();
    }
  }

  return (
    <>
      <Nav />
      <Box className={classes.root}>
        <Layout>
          <MyBreadcrumbs />
          {component}
        </Layout>
        ;
      </Box>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 26,
    background: theme.palette.background.paper,
    height: "88vh",
  },
}));
