import { Box, Button, Dialog, DialogActions, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface IDeleteModal {
  open: boolean;
  closeWindow: () => void;
  onCancel?: () => void;
  onOk: any;
  content?: any;
  model: string;
}

export const DeleteModal: React.FC<IDeleteModal> = ({ model, content, open, closeWindow, onCancel, onOk }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} classes={{ paper: classes.root }}>
      <IconButton onClick={closeWindow} className={classes.closeIcon}>
        <CloseIcon color="error" />
      </IconButton>
      {content ? (
        content
      ) : (
        <>
          <Box mt={2}>
            <Typography>Do you really want to remove this from {model}</Typography>
          </Box>
          <Box mt={2}>
            <DialogActions>
              <Button variant="outlined" color="secondary" className={classes.cancelBtn} onClick={onCancel ? onCancel : closeWindow}>
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.approveBtn}
                onClick={() => {
                  onOk(), closeWindow();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Box>
        </>
      )}
    </Dialog>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    padding: "30px 30px",
    minWidth: 500,
  },
  closeIcon: {
    position: "absolute",
    right: 5,
    top: 0,
    padding: 3,
    cursor: "pointer",
  },
  approveBtn: {},
  cancelBtn: {},
}));
