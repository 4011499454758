import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { INavigation } from "interfaces/INavigation";

export const NavLink: React.FC<INavigation> = ({ name, link }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const checkIsActive = () => {
    if (pathname === "/dashboard" && pathname === link) {
      return true;
    }
    if (pathname !== "/dashboard" && pathname.includes(link)) {
      return true;
    }
  };

  return (
    <Link className={clsx(classes.root, checkIsActive() && classes.active)} to={link}>
      {name}
    </Link>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.light,
    fontSize: 15,
    textDecoration: "none",
    marginRight: 45,
    paddingBottom: 20,
    paddingTop: 24,
    fontWeight: 600,
    cursor: "pointer !important",
    fontFamily: "Roboto",
  },
  active: {
    color: theme.palette.primary.main,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
}));
