import { Box, TextField, Typography } from "@material-ui/core";
import { inputStyles } from "./styles";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import React from "react";
import { ITextField } from "./MyDropdown";

interface IMyInput extends ITextField {
  nmb?: any;
  textarea?: boolean;
  minRows?: number;
  type?: string;
  label?: string;
  placeholder?: string;
  resize?: any;
  multiline?: any;
}

export const MyInput: React.FC<IMyInput> = ({ nmb, textarea, minRows, rules, control, name, type, error, label, placeholder, multiline }) => {
  const classes = inputStyles({
    nmb: nmb,
    multiline: multiline,
  });

  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <TextField
            multiline={textarea}
            minRows={textarea ? minRows : 0}
            classes={{ root: clsx(minRows && classes.textArea, classes.textField) }}
            helperText={error?.message}
            type={type ? type : "text"}
            error={Boolean(error)}
            placeholder={placeholder}
            variant={"outlined"}
            {...field}
          />
        )}
      />
    </Box>
  );
};
