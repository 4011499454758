import { checkFileExist } from "./checkFileExist";

export const fillFormData = (obj: any): FormData => {
  const formData = new FormData();
  checkFileExist(obj.image, obj.imageFromServer, formData);
  for (const prop in obj) {
    formData.append(prop, String(obj[prop]));
  }
  return formData;
};
