import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { DeleteModal, GreyText, MyText } from "./shared";
import { useModalWindow } from "../hooks";
import { ChangeOrderPriceDto } from "dto";
import { ArrowIcon } from "./ArrowIcon";
import { CategoryDirection } from "enums/CategoryDirection";

export interface IEventItem {
  id: number;
  status: boolean;
  headline: string;
  text: string;
  image?: string;
  intro?: string;
  date: string;
  changeStatusAction: (id: number, status: boolean) => void;
  notBorders?: boolean;
  onDelete: () => void;
  isArrows?: boolean;
  index: number;
  lastIndex: number;
  changeOrderAction: (data: ChangeOrderPriceDto) => void;
}

export const EventItem: React.FC<IEventItem> = ({ id, status, headline, date, notBorders, changeStatusAction, onDelete, isArrows, lastIndex, index, changeOrderAction }) => {
  const classes = useStyles({ notBorders });
  const navigate = useNavigate();
  const { open, openWindow, closeWindow } = useModalWindow();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6} className={classes.headline}>
        <MyText text={headline} onClick={() => navigate(`${id}`)} />
      </Grid>
      <Grid item xs={3} className={classes.textRight}>
        <MyText text={date} onClick={() => navigate(`${id}`)} />
      </Grid>

      <Grid item xs={1} className={classes.textRight}>
        <Status active={status} id={id} changeStatus={changeStatusAction} />
      </Grid>

      <Grid item>
        {isArrows && (
          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              {lastIndex !== index && (
                <Box
                  onClick={() =>
                    changeOrderAction({
                      mainId: id,
                      direction: CategoryDirection.PREV,
                    })
                  }
                >
                  <ArrowIcon />
                </Box>
              )}
              {index !== 0 && (
                <Box
                  onClick={() =>
                    changeOrderAction({
                      mainId: id,
                      direction: CategoryDirection.NEXT,
                    })
                  }
                >
                  <ArrowIcon up={true} />
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid item xs={1}>
        <GreyText
          isPointer={true}
          align="right"
          withUnderline={true}
          text={"delete"}
          onClick={() => {
            openWindow("delete");
          }}
        />
      </Grid>
      <DeleteModal model="events" open={open} closeWindow={closeWindow} onOk={onDelete} />
    </Grid>
  );
};

export interface IStatus {
  active?: boolean;
  id: number;
  changeStatus: (id: number, status: boolean) => void;
}

export const Status: React.FC<IStatus> = ({ active, changeStatus, id }) => {
  const classes = useStyles({ notBorders: false });
  return (
    <Typography onClick={() => changeStatus(id, !active)} className={clsx(active ? classes.status : classes.statusInActive)}>
      {active ? "active" : "inactive"}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { notBorders?: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: props.notBorders ? "11px 0" : "21px 0",
    borderTop: props.notBorders ? "" : `1px solid ${theme.palette.text.secondary}`,
    "&:last-child": {
      borderBottom: props.notBorders ? "" : `1px solid ${theme.palette.text.secondary}`,
    },
  }),
  status: {
    cursor: "pointer",
    color: theme.palette.success.main,
  },
  statusInActive: {
    cursor: "pointer",
    color: theme.palette.error.main,
  },
  headline: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textRight: {
    textAlign: "right",
  },
}));
