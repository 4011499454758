import { makeStyles, Theme } from "@material-ui/core";

interface IInputStyles {
  isAdditionalOptions?: boolean;
  nmb?: number;
  multiline?: any;
  autocomplete?: boolean;
}

export const inputStyles = makeStyles((theme: Theme) => ({
  "& .MuiMenu-paper": {
    maxHeight: 500,
    overflowY: "auto",
  },
  label: {
    // fontSize: 13,
    marginBottom: 5,
    position: "absolute",
    top: -25,
    fontWeight: "bold",
  },
  additionalMenuItem: {
    "& .MuiMenu-paper": {
      maxHeight: 500,
      overflowY: "auto",
    },
    "& li": {
      textTransform: "capitalize",
    },
  },
  options: (props: IInputStyles) => ({
    paddingLeft: props.isAdditionalOptions ? 47 : 10,
    maxHeight: 500,
    overflowY: "auto",
  }),
  textArea: {
    "& textarea": {
      "&::placeholder": {
        fontSize: 15,
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red",
      fontWeight: 400,
      fontSize: 14,
      margin: 0,
      position: "absolute",
      bottom: -25,
      height: 20,
      marginTop: "20px !important",
      lineHeight: 1.2,
    },
    "& .MuiOutlinedInput-root": {
      height: "100% !important",
      "&:hover fieldset": {
        border: "1px solid black !important",
      },
    },
  },
  textFieldActive: {
    "& input:valid + fieldset": {
      border: "1px solid black",
    },
  },
  paper: {
    maxHeight: "500px !important",
    overflowY: "auto",
  },
  borderActive: {
    "& fieldset": {
      border: "1px solid black ",
    },
  },
  dropdown: {
    "& .MuiMenu-paper": {
      maxHeight: 500,
      overflowY: "auto",
    },
    "& .MuiList-root": {
      maxHeight: 500,
      overflowY: "auto",
    },
  },
  datepciker: {
    position: "relative",
    width: "100%",
    marginBottom: 42,
  },
  textField: (props: IInputStyles) => {
    return {
      width: "100%",
      borderRadius: 2,
      position: "relative",
      background: "#F8F9FB",
      marginBottom: props.nmb ? 0 : 42,
      color: "red",
      "&:hover input:valid + fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        border: "1px solid #C0C0C0",
      },

      "& .Mui-error": {
        "&:hover fieldset": {
          border: "1px solid red !important",
        },
        "&.Mui-focused fieldset": {
          border: "1px solid red !important",
        },
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow !important",
      },
      "& :nth-child": {
        maxHeight: 500,
        overflowY: "auto",
      },
      "& input": {
        padding: "0px !important",
        paddingLeft: "24px !important",
        height: props.autocomplete ? "initial" : props.multiline ? 100 : 48,
        minHeight: 48,
        fontSize: 15,
        "&::placeholder": {
          fontSize: 15,
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
      "& .MuiOutlinedInput-root": {
        height: props.autocomplete ? "initial" : props.multiline ? 100 : 48,
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: "red",
        fontWeight: 400,
        fontSize: 14,
        margin: 0,
        position: "absolute",
        marginTop: 50,
        lineHeight: 1.2,
      },
      "& MuiAutocomplete-root": {
        padding: "0px !important",
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "0px !important",
      },
      "& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root": {
        padding: "0px !important",
      },
    };
  },
}));
