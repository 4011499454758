import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/authSlice";
import { useAppDispatch } from "../redux/hooks";
import toast from "react-hot-toast";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    dispatch(logout());
    navigate("/");
    toast.success("Successfully logout");
  };

  return [handleLogout];
};
