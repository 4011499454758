import { Box, Grid, makeStyles } from "@material-ui/core";
import { CategoryItem, DeleteModal, MyButton, MyCheckbox, Preloader, PriceFundLinkForm, Subtitle, Title } from "../../../components";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useModalWindow } from "hooks";
import {
  useChangeOrderPriceFundLinkMutation,
  useChangeStatusPriceFundLinkMutation,
  useCreatePriceFundLinkMutation,
  useDeletePriceFundLinkMutation,
  useEditPriceFundLinkMutation,
  useGetCountriesQuery,
  useGetAllCountriesQuery,
  useGetPriceFundLinksQuery,
  useGetPriceFundQuery,
  useSaveLinkCountriesMutation,
} from "api";
import { useForm } from "react-hook-form";
import { ICountry, IPriceFundCountry, IPriceFundLink } from "models";
import { ChangeOrderPriceDto, ChangeStatusPriceDto } from "dto";

export interface ICountryDto {
  available: boolean;
  countryId: number;
}

export const PriceFund = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  //GLOBAL_HOOKS
  const { name, open, openWindow, closeWindow } = useModalWindow();

  //LOCAL_STATES
  const [currentLink, setCurrentLink] = useState<IPriceFundLink | null>(null);
  const [defaultCountries, setDefaultCountries] = useState<number[]>([]);

  //FORM_VALUES
  const { handleSubmit, control } = useForm({ mode: "onChange" });

  //REQUESTS
  const { data: countries, isLoading: countriesIsLoading } = useGetAllCountriesQuery();
  const { data, isLoading } = useGetPriceFundQuery(Number(id));
  const { data: links, isLoading: isGettingLinks } = useGetPriceFundLinksQuery(Number(id));
  const [editLink] = useEditPriceFundLinkMutation();
  const [createLink] = useCreatePriceFundLinkMutation();
  const [deleteLink] = useDeletePriceFundLinkMutation();
  const [changeStatus] = useChangeStatusPriceFundLinkMutation();
  const [changeOrder] = useChangeOrderPriceFundLinkMutation();
  const [saveCountries] = useSaveLinkCountriesMutation();

  //EFFECTS
  useEffect(() => {
    if (data?.funds_countries) {
      setDefaultCountries(data.funds_countries?.length > 0 ? data.funds_countries.map((i: IPriceFundCountry) => i.countryId) : []);
    }
  }, [id, data, isLoading]);

  //HANDLERS
  const setCurrentLinkId = (id: number) => {
    setCurrentLink({
      status: false,
      text: "",
      url: "",
      id,
    });
  };

  const onCreateLink = (data: IPriceFundLink) => {
    createLink({
      ...data,
      fundId: id,
    });
  };
  const onChangeLink = (data: IPriceFundLink) => {
    editLink(data);
    closeWindow();
  };
  const onDeleteLink = () => {
    if (currentLink) {
      deleteLink(currentLink.id);
    }
  };
  const onChangeStatus = (data: ChangeStatusPriceDto) => {
    changeStatus(data);
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder({
      mainId: data.mainId,
      secondaryId: Number(id),
      direction: data.direction,
    });
  };

  const handleCheck = (value: boolean, checkedId: number) => {
    setDefaultCountries(value ? [...defaultCountries, checkedId] : defaultCountries.filter((i: number) => i !== Number(checkedId)));
  };

  //SUBMIT_FORM
  const onSaveCountries = () => {
    const countryIds: ICountryDto[] = [];
    if (countries && id) {
      for (const countriesKey of countries) {
        countryIds.push({
          countryId: countriesKey.id,
          available: defaultCountries.includes(countriesKey.id),
        });
      }

      saveCountries({
        id: Number(id),
        countryIds,
      });
      navigate(`/dashboard/prices`);
    }
  };

  if (isGettingLinks || countriesIsLoading) {
    return <Preloader isLoading={true} />;
  }

  return (
    <>
      <Title text={`${data?.name}`} />
      <Grid container className={classes.root}>
        <Grid item xs={12} md={5} xl={4}>
          <Box mb={2}>
            <Subtitle text={`${data?.name} is available in `} />
          </Box>
          <form onSubmit={handleSubmit(onSaveCountries)}>
            {countries &&
              countries?.length > 0 &&
              countries.map((item: ICountry) => (
                <MyCheckbox key={item.id} onChange={handleCheck} control={control} label={item.name} name={String(item.id)} checked={defaultCountries?.includes(item.id)} />
              ))}

            <Box my={3}>
              <MyButton width={150} text="Save" type="submit" />
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} md={7} xl={4}>
          {links && links?.length > 0 && (
            <Box mb={8}>
              <Box mb={2}>
                <Subtitle text={`Links for ${data?.name}`} />
              </Box>
              {links?.map((l, index) => (
                <CategoryItem
                  nameIsNotClickable={true}
                  key={l.id}
                  id={l.id}
                  name={l.text}
                  status={l.status}
                  isStatus={true}
                  isArrows={true}
                  index={index}
                  lastIndex={links.length - 1}
                  changeOrderAction={onChangeOrder}
                  changeStatusAction={onChangeStatus}
                  deleteAction={(id: number) => setCurrentLinkId(id)}
                  openWindow={openWindow}
                />
              ))}
            </Box>
          )}
          <Subtitle text={`Add link for ${data?.name}`} />
          <PriceFundLinkForm action={onCreateLink} />
        </Grid>
      </Grid>
      <DeleteModal
        model="fund prices"
        content={name === "edit" && <PriceFundLinkForm link={currentLink} isEdit={true} action={onChangeLink} />}
        open={open}
        closeWindow={closeWindow}
        onOk={onDeleteLink}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 40,
  },
  countriesForm: {
    marginBottom: 20,
  },
  priceForm: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 30,
  },
  fundsForm: {
    transform: "rotate(180deg)",
  },

  //FUND_ITEM
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
  },
}));
