import { createAsyncThunk } from "@reduxjs/toolkit";
import { authAPI } from "../../api";
import { AuthLoginDto } from "../../dto/auth";
import { toast } from "react-hot-toast";

export const login = createAsyncThunk("auth/login", async (args: AuthLoginDto, thunkAPI) => {
  try {
    const payload = await authAPI.login(args);
    toast.success("Successfully logged in admin panel");
    return payload;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response ? err?.response?.data?.message : "Login Error");
  }
});
