import {
  Contact,
  Dashboard,
  Esg,
  Login,
  Media,
  MediaCategoryDetail,
  News,
  NewsDetails,
  NewsDetailsById,
  Palette,
  PaletteFund,
  Palettes,
  Preload,
  Price,
  PriceFund,
  Prices,
  StartPage,
  TextContent,
  Events,
  EventDetails,
  EventDetailsById,
} from "../pages";
import { PrivateRoute } from "../components";
import { StartPageCategory } from "../pages/dashboard/startPage/StartPageCategory";
import React from "react";
import { Push } from "pages/dashboard/push/Push";
import { EsgPageCategory } from "pages/dashboard/esg/EsgCategory";

export const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: `/dashboard/start`,
    breadcrumb: "Start page",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<StartPage />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "Edit Category",
        element: (
          <PrivateRoute>
            <Dashboard component={<StartPageCategory />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/prices",
    breadcrumb: "Fund Prices",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<Prices />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "Edit Fund Category",
        element: (
          <PrivateRoute>
            <Dashboard component={<Price />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/prices/:id/fund",
    children: [
      {
        path: ":id",
        breadcrumb: "Edit Fund",
        element: (
          <PrivateRoute>
            <Dashboard component={<PriceFund />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/palettes",
    breadcrumb: "Fund Palettes",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<Palettes />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "Edit Category",
        element: (
          <PrivateRoute>
            <Dashboard component={<Palette />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/palettes/:id/fund",
    children: [
      {
        path: ":id",
        breadcrumb: "Edit Fund",
        element: (
          <PrivateRoute>
            <Dashboard component={<PaletteFund />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/text_content",
    breadcrumb: "Text Content",
    element: (
      <PrivateRoute>
        <Dashboard component={<TextContent />} />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard/news",
    breadcrumb: "News",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<News />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "Edit News",
        element: (
          <PrivateRoute>
            <Dashboard component={<NewsDetailsById />} />
          </PrivateRoute>
        ),
      },
      {
        path: "details",
        index: false,
        breadcrumb: "News Details",
        element: (
          <PrivateRoute>
            <Dashboard component={<NewsDetails />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/contact",
    breadcrumb: "Contact",
    element: (
      <PrivateRoute>
        <Dashboard component={<Contact />} />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard/preload",
    breadcrumb: "Preload",
    element: (
      <PrivateRoute>
        <Dashboard component={<Preload />} />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard/esg",
    breadcrumb: "Esg",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<Esg />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "Edit Category",
        element: (
          <PrivateRoute>
            <Dashboard component={<EsgPageCategory />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/media",
    breadcrumb: "Media",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<Media />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "edit media category",
        element: (
          <PrivateRoute>
            <Dashboard component={<MediaCategoryDetail />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/events",
    breadcrumb: "Events",
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard component={<Events />} />
          </PrivateRoute>
        ),
      },
      {
        path: ":id",
        breadcrumb: "Edit Event",
        element: (
          <PrivateRoute>
            <Dashboard component={<EventDetailsById />} />
          </PrivateRoute>
        ),
      },
      {
        path: "details",
        index: false,
        breadcrumb: "Event Details",
        element: (
          <PrivateRoute>
            <Dashboard component={<EventDetails />} />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboard/push",
    breadcrumb: "Push",
    element: (
      <PrivateRoute>
        <Dashboard component={<Push />} />
      </PrivateRoute>
    ),
  },
];
