import { Button, makeStyles, Theme } from "@material-ui/core";
import React from "react";

interface IMyButton {
  text: string;
  type?: string;
  onClick?: () => void;
  disabled?: boolean;
  width?: number;
}

export const MyButton = ({ width, onClick, text, type, disabled }: IMyButton) => {
  const classes = useStyles({ width: width });
  return (
    <Button className={classes.default} disabled={disabled} variant="contained" color="primary" type={type ? type : "button"} href={""} onClick={onClick}>
      {text}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  default: (props: { width?: number; notMl?: boolean }) => ({
    minWidth: 179,
    height: 48,
    width: props.width && props.width,
    borderRadius: 6,
    fontSize: 15,
    color: theme.palette.background.default,
    textTransform: "capitalize",
    "&:hover": {
      background: "#2162E7",
    },
    "&:active": {
      background: "#2162E7",
    },
    "&:focus": {
      background: "#2162E7",
    },
    "&:disabled": {
      background: theme.palette.text.secondary,
      color: "white",
    },
  }),
}));
