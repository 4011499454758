import { FileType } from "../types";

export const checkFileExist = (img: FileType, imgFromServer: FileType, formData: FormData): void => {
  if (img || imgFromServer) {
    if (imgFromServer && typeof img === "string") {
      formData.append("isImage", String(true));
    } else {
      if (img) {
        formData.append("image", img);
      }
    }
  }
};
