import { IUrlValidation, urlValidation } from "../components/shared/Inputs/validations";

export const colorsData = [
  {
    name: "bg_color_teaser",
    label: "Background color - Teaser",
  },
  {
    name: "icon_color_teaser",
    label: "Icon color - Teaser",
  },
  {
    name: "text_color_teaser",
    label: "Font text color - Teaser",
  },
] as { name: string; label: string }[];

export const linksData = [
  {
    name: "link_text1",
    label: "Link text 1",
  },
  {
    name: "link_url1",
    label: "Link URL 1",
    rules: urlValidation(),
  },
  {
    name: "link_text2",
    label: "Link text 2",
  },
  {
    name: "link_url2",
    label: "Link URL 2",
    rules: urlValidation(),
  },
] as { name: string; label: string; rules: IUrlValidation }[];

export const fundData = [
  {
    name: "name_overview",
    label: "Fund Name - Overview",
  },
  {
    name: "name_page",
    label: "Fund Name - Page",
  },
  {
    name: "headline",
    label: "Headline - Page",
    isTextarea: true,
  },
  {
    name: "strategy",
    label: "Fund Strategy - Page",
    isTextarea: true,
  },
] as { name: string; label: string; isTextarea: boolean }[];
