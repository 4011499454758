import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { Controller } from "react-hook-form";
import React from "react";
import { ITextField } from "./MyDropdown";

interface IMyCheckbox extends Pick<ITextField, "name" | "control"> {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean, name: number) => void;
}

export const MyCheckbox: React.FC<IMyCheckbox> = ({ control, name, label, checked, onChange }) => {
  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => <Checkbox {...field} checked={checked} onChange={(e) => onChange(e.target?.checked, Number(name))} color="primary" />}
          />
        }
      />
    </Box>
  );
};
