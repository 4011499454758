import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EventsState {
  name: string;
}

const initialState: EventsState = {
  name: "",
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEventName(state: EventsState, action: PayloadAction<string>) {
      state.name = action.payload;
    },
  },
});

const { actions } = eventsSlice;
// Extract and export each action creator by name
export const { setEventName } = actions;
