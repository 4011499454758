import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles, Theme } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import { AddEditCategoryForm, CategoryItem, DeleteModal, MyButton, MyFilePicker, MyInput, Preloader, Title } from "components";
import { useHandleFile, useModalWindow } from "hooks";
import {
  useChangeOrderEsgPageCategoryMutation,
  useChangeStatusEsgPageCategoryMutation,
  useCreateEsgPageCategoryMutation,
  useDeleteEsgPageCategoryMutation,
  useGetEsgPageCategoriesQuery,
  useGetEsgPageQuery,
  useGetNewsQuery,
  useUpdateEsgPageMutation,
} from "api";
import { ESG_PAGE_DROPDOWN_OPTIONS, ESG_PAGE_DROPDOWN_VALUES } from "constants/esg_page";
import { INews } from "models/INews";
import { FileType, Id } from "types";
import { urlValidation } from "components/shared/Inputs/validations";
import { StartDropdown } from "components/shared/Inputs/StartDropdown";
import { IMAGE } from "constants/imagesConst";
import { ICategory } from "models";
import { ChangeOrderPriceDto, ChangeStatusPriceDto } from "dto";
interface IEsgPageFormValues {
  headline: string;
  subline: string;
  button_text: string;
  link: string;
  url: string;
}

export interface IUpdateEsgPageFormDto extends IEsgPageFormValues {
  id: Id;
  newsId: Id;
  image: FileType;
  imageFromServer: FileType;
  isExternal: boolean;
}

export const Esg = () => {
  const classes = useStyles();
  const [currentPriceId, setCurrentPriceId] = useState<number | null>(null);
  const [showExternalUrl, setShowExternalLink] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [addCategory, { isLoading: isAdding }] = useCreateEsgPageCategoryMutation();
  const [deletePrice] = useDeleteEsgPageCategoryMutation();
  const [changeStatus] = useChangeStatusEsgPageCategoryMutation();
  const [changeOrder] = useChangeOrderEsgPageCategoryMutation();

  const { data: esgPage } = useGetEsgPageQuery();
  const [updateEsgPage, { isLoading: isUpdatingEsgPage }] = useUpdateEsgPageMutation();

  const { data: categories, isLoading } = useGetEsgPageCategoriesQuery();

  const { open, openWindow, closeWindow } = useModalWindow();
  const { data: news, isLoading: isLoadingNews } = useGetNewsQuery(page);
  const { file: image, handleChangeFile: handleChangeManagerImage } = useHandleFile();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<IEsgPageFormValues>({
    mode: "onChange",
  });
  const fields = watch();
  useEffect((): void => {
    if (esgPage) {
      setValue("headline", esgPage.headline);
      if (esgPage.subline) {
        setValue("subline", esgPage.subline);
      }
      if (esgPage.button_text) {
        setValue("button_text", esgPage.button_text);
      }
      if (esgPage.url) {
        setValue("url", esgPage.url);
      }
      if (esgPage.image) {
        handleChangeManagerImage(esgPage.image);
      }
      if (esgPage.isExternal) {
        setValue("link", ESG_PAGE_DROPDOWN_VALUES.EXTERNAL_LINK);
        setShowExternalLink(true);
      } else {
        if (esgPage.newsId && news) {
          const _newsItem = news.items.find((i) => i.id === esgPage.newsId);
          setValue("link", _newsItem?.headline ? _newsItem?.headline : "");
        }
      }
    }
  }, [esgPage, news]);

  const onChangeStatus = (data: ChangeStatusPriceDto) => {
    changeStatus(data);
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder(data);
  };

  const onDelete = () => {
    if (currentPriceId) {
      deletePrice(currentPriceId);
    }
  };

  const onSubmit = (data: any): void => {
    if (esgPage?.id) {
      const dto = {} as IUpdateEsgPageFormDto;
      for (const prop in data) {
        if (prop === "url" || prop === "link") {
          if (showExternalUrl) {
            dto.isExternal = true;
          } else {
            const _newsItem = news && news.items.find((i) => i.headline?.toUpperCase() === data?.link.toUpperCase());
            _newsItem ? (dto.newsId = _newsItem?.id) : "";
            dto.isExternal = false;
          }
        }
      }

      updateEsgPage({
        ...data,
        ...dto,
        id: Number(esgPage.id),
        imageFromServer: esgPage.image,
        image,
      });
    }
  };
  const handleChangeLink = (e: { label: string; value: number }) => {
    if (e.label === ESG_PAGE_DROPDOWN_VALUES.EXTERNAL_LINK) {
      setShowExternalLink(true);
      setValue("url", "");
    } else {
      setShowExternalLink(false);
      setValue("url", "");
    }
    if (e.value) {
      setValue("link", String(e.label));
    }
  };
  const goNext = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Box mb={2}>
        <Title text="Esg Teaser" />
      </Box>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Box mb={5}>
          <Grid container>
            <Grid item xs={4}>
              <MyFilePicker file={image} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="space-between" mb={4}>
          <Grid item container xs={4}>
            <Grid item xs={12} mb={2}>
              <MyInput label="Headline" placeholder="Headline" control={control} name="headline" error={errors.headline} />
            </Grid>
            <Grid item xs={12} mb={2}>
              <MyInput label="Subline" placeholder="Subline" control={control} name="subline" rules={{ required: "Subline is required" }} error={errors.subline} />
            </Grid>
            <Grid item xs={12} mb={2}>
              <MyInput
                nmb={true}
                label="Button Text"
                placeholder="Button Text"
                control={control}
                name="button_text"
                rules={{ required: "Button text is required" }}
                error={errors.button_text}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="inherit">
            <Grid item xs={12}>
              <StartDropdown
                options={news ? news.items.map((i: INews) => ({ name: i.headline, id: i.id })) : []}
                value={fields.link}
                label="Link to"
                nmb={true}
                placeholder="- link to -"
                name="link"
                select={true}
                onClick={handleChangeLink}
                additionalOptions={ESG_PAGE_DROPDOWN_OPTIONS}
                goNext={goNext}
              />
            </Grid>
            {showExternalUrl && (
              <Grid item xs={12}>
                <MyInput label="URL" rules={urlValidation()} placeholder="URL" control={control} name="url" error={errors.url} />
              </Grid>
            )}
            <Grid item xs={12} mb={2}></Grid>
          </Grid>
        </Grid>
        <Grid container>
          <MyButton disabled={isUpdatingEsgPage} width={150} text="Save" type="submit" />
        </Grid>
      </form>
      <Box mb={2}>
        <Title text="Category" />
      </Box>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          {categories &&
            categories.map((i: ICategory, index: number) => (
              <CategoryItem
                openWindow={openWindow}
                changeStatusAction={onChangeStatus}
                deleteAction={(id: number) => setCurrentPriceId(id)}
                changeOrderAction={onChangeOrder}
                lastIndex={categories.length - 1}
                id={i.id}
                index={index}
                key={i.id}
                name={i.name}
                isStatus={true}
                status={i.status}
                isArrows={true}
                nextPath={`/dashboard/esg/${i.id}`}
              />
            ))}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <AddEditCategoryForm action={(name: string) => addCategory({ name })} />
        </Grid>
      </Grid>
      <DeleteModal model={"esg"} open={open} closeWindow={closeWindow} onOk={onDelete} />
      <Preloader isLoading={isUpdatingEsgPage || isLoadingNews} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 30,
  },
  mb: {
    paddingBottom: 40,
  },
  deleteText: {
    cursor: "pointer",
    marginLeft: "18px",
    textDecoration: "underline",
    color: "#666666",
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));
