import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles, Theme } from "@material-ui/core";
import { Title, MyFilePicker, MyButton, Preloader } from "components";
import { useHandleFile } from "hooks";
import { useGetPreloadQuery, useUpdatePreloadMutation } from "api";
import { FileType, Id } from "types";
import { IMAGE } from "constants/imagesConst";
interface PreloadPageFormValues {
  isImage: boolean;
}

export interface UpdatePreloadPageFormDto extends PreloadPageFormValues {
  id: Id;
  image: FileType;
}

export const Preload = () => {
  const classes = useStyles();

  const { data: preloadPage, isLoading } = useGetPreloadQuery();
  const [updatePreloadPage, { isLoading: isUpdatingPreloadPage }] = useUpdatePreloadMutation();

  const { handleSubmit } = useForm<PreloadPageFormValues>({
    mode: "onChange",
  });

  useEffect(() => {
    if (preloadPage) {
      if (preloadPage.image) {
        handleChangeManagerImage(preloadPage.image);
      }
    }
  }, [preloadPage]);

  const saveInform = (data: PreloadPageFormValues): void => {
    if (preloadPage?.id) {
      const formData: any = new FormData();

      formData.append("isImage", true);
      formData.append("image", image);
      formData.append("id", Number(preloadPage.id));

      updatePreloadPage(formData);
    }
  };

  const { file: image, handleChangeFile: handleChangeManagerImage } = useHandleFile();
  return (
    <>
      <form className={classes.root} onSubmit={handleSubmit(saveInform)}>
        <Box mb={2}>
          <Title text="Preload Images" />
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <MyFilePicker file={image} setFile={handleChangeManagerImage} size={IMAGE.PRELOAD_SIZE} />
          </Grid>
        </Grid>
        <Box mt={4}>
          <MyButton disabled={!image} width={150} text="Save" type="submit" />
        </Box>
      </form>
      <Preloader isLoading={isLoading || isUpdatingPreloadPage} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 80,
  },
  mb: {
    paddingBottom: 40,
  },
  deleteText: {
    cursor: "pointer",
    marginLeft: "18px",
    textDecoration: "underline",
    color: "#666666",
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
  rotate: {
    transform: "rotate(180deg)",
  },
  form: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 59,
    "& button": {
      marginLeft: 20,
    },
  },
}));
