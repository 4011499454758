import { NavLink } from "../components";
import { NAVIGATION_ITEMS } from "../constants/navigation";
import { Box, Button, makeStyles, Theme } from "@material-ui/core";
import { useLogout } from "../hooks";
import React from "react";
import { INavigation } from "../interfaces/INavigation";
import clsx from "clsx";

export const Nav = () => {
  const classes = useStyles();
  const [logout] = useLogout();

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        {NAVIGATION_ITEMS.map((i: INavigation) => (
          <NavLink key={i.name} name={i.name} link={i.link} />
        ))}
      </Box>
      <Button onClick={logout} className={clsx(classes.btn)}>
        Logout
      </Button>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignContent: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 100,
    paddingRight: 100,
    background: "white",
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.12)",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 20,
    textTransform: "capitalize",
    padding: 0,
    minWidth: 0,
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.primary.light,
    cursor: "pointer !important",
    "&:hover": {
      background: "none",
      color: theme.palette.primary.main,
    },
  },
}));
