import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { MyButton, MyDropdown, MyFilePicker, MyInput, Preloader, Title } from "components";
import { inputStyles } from "components/shared/Inputs/styles";
import { useHandleFile } from "hooks";
import { useGetTagsQuery, useSaveNewsMutation } from "api";
import { useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router";
import { NewsConst } from "constants/news_type";
import { MyTags } from "components/shared/Inputs/MyTags";
import { IDropdownOption } from "interfaces/IDropdownOption";
import { ITag } from "models";
import { urlValidation } from "components/shared/Inputs/validations";
import { IMAGE } from "constants/imagesConst";
import TextEditor from "components/TextEditor";
import { useHandleMP3 } from "hooks/useHandleMP3";
import { MyMP3Picker } from "components/MyMP3Picker";

export interface StartNewsDetailsFormValues {
  newstype: string;
  headline: string;
  text?: string | null;
  url: string;
  link: string;
  tags: ITag[];
}

export const NewsDetails = () => {
  const { type: typeReducer } = useAppSelector(({ news }) => news);
  const [saveNews] = useSaveNewsMutation();
  const classes = inputStyles({
    nmb: 0,
    multiline: false,
  });
  const navigate = useNavigate();

  const [newsType, setNewsType] = useState<any>();
  const [currentTags, setCurrentTags] = useState<IDropdownOption[] | []>([]);
  const { data: tags, isLoading: isGettingTags } = useGetTagsQuery();

  useEffect(() => {
    setNewsType(typeReducer);
  }, [typeReducer]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<StartNewsDetailsFormValues>({ mode: "onChange" });

  const handleSelectTags = (newTag: IDropdownOption | null, isDelete?: boolean, isClear?: boolean): void => {
    if (isClear) {
      setCurrentTags([]);
    } else {
      if (newTag) {
        setCurrentTags((prev): IDropdownOption[] => {
          if (isDelete) {
            return prev.filter((i) => i.name !== newTag.name);
          } else {
            return [newTag, ...prev];
          }
        });
      }
    }
  };

  const handleEnterTags = (newTag: IDropdownOption) => {
    setCurrentTags((prev) => [...prev, newTag]);
  };
  const { file: managerImage, handleChangeFile: handleChangeManagerImage } = useHandleFile();
  const { file: managerMP3, handleChangeFile: handleChangeManagerMP3 } = useHandleMP3();

  const onSubmit = (data: any) => {
    const formData: any = new FormData();
    for (const prop in data) {
      if (data[prop]) {
        formData.append(prop, data[prop]);
      }
    }

    if (managerMP3) {
      formData.set("file", managerMP3);
    }

    if (!data.link) {
      formData.set("link", "");
    } else {
      formData.set("link", data.link.replaceAll(" ", ""));
    }

    if (!data.text) {
      formData.set("text", "");
    }
    if (managerImage) {
      formData.append("image", managerImage);
    }
    if (tags) {
      formData.set(
        "tags",
        JSON.stringify(
          currentTags.map((ct) => {
            if (ct.isNew === true) {
              const tag = tags?.find((tag) => tag.name === ct.name);
              if (tag) {
                return {
                  name: ct.name,
                  id: tag.id,
                  isNew: false,
                };
              } else {
                return {
                  name: ct.name,
                  id: -1,
                  isNew: true,
                };
              }
            } else {
              return {
                name: ct.name,
                id: ct.id,
                isNew: false,
              };
            }
          }),
        ),
      );
    }

    if (!formData.get("newstype")) {
      formData.append("newstype", newsType);
    }

    saveNews(formData).then(() => {
      reset({
        text: "",
        headline: "",
        tags: [],
      });
      handleChangeManagerImage(null);
      handleChangeManagerMP3(null);
      formData.delete("tags");
      navigate(`/dashboard/news`);
    });
  };

  return (
    <>
      <Grid container>
        <Box mb={8}>
          <Title text="News Details" />
        </Box>
      </Grid>
      <form
        style={{
          display: "flex",
          gap: "100px",
          width: "max-width",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <Box mb={8}>
            <MyDropdown
              options={[
                {
                  name: "Video",
                  id: 1,
                },
                {
                  name: "Podcast",
                  id: 2,
                },
                {
                  name: "Insight",
                  id: 3,
                },
              ]}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewsType(e.target.value);
                setValue("newstype", e.target.value);
              }}
              select={true}
              label="News Type"
              nmb={true}
              placeholder="-Type-"
              control={control}
              name="newstype"
              value={newsType}
              rules={{ required: false }}
              error={errors.newstype}
            />
          </Box>
          {newsType === NewsConst.INSIGHT && (
            <>
              <Box mb={8}>
                <MyInput label="Headline" nmb={true} placeholder="Headline" control={control} name="headline" error={errors.headline} />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>Text</Typography>
              <Box mb={8}>
                <Controller
                  rules={{ required: "News text is required" }}
                  render={({ field }) => <TextEditor {...field} error={errors.text} />}
                  name="text"
                  control={control}
                  defaultValue=""
                />
              </Box>
              <Box mb={8}>
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </>
          )}
          {newsType === NewsConst.VIDEO && (
            <>
              <Box mb={8}>
                <MyInput label="Headline" nmb={true} placeholder="Headline" control={control} name="headline" error={errors.headline} />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>Text</Typography>
              <Box mb={8}>
                <Controller render={({ field }) => <TextEditor {...field} />} name="text" control={control} defaultValue="" />
              </Box>

              <Box mb={8}>
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box mb={8}>
                  <MyInput label="Video Link" nmb={true} rules={urlValidation(true)} control={control} name="link" error={errors.link} placeholder="URL" />
                </Box>
              </div>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </>
          )}
          {newsType === NewsConst.PODCAST && (
            <>
              <Box mb={8}>
                <MyInput label="Headline" nmb={true} placeholder="Headline" control={control} name="headline" error={errors.headline} />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>Text</Typography>
              <Box mb={8}>
                <Controller
                  rules={{ required: "News text is required" }}
                  render={({ field }) => <TextEditor {...field} error={errors.text} />}
                  name="text"
                  control={control}
                  defaultValue=""
                />
              </Box>

              <Box mb={8}>
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box mb={8}>
                  <MyMP3Picker file={managerMP3} setFile={handleChangeManagerMP3} />
                </Box>
              </div>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </>
          )}
        </Grid>

        <Box
          style={{
            position: "relative",
            maxWidth: "400px",
            minWidth: "300px",
          }}
        >
          <Typography className={classes.label}>Tags</Typography>
          <MyTags
            updateValue={setValue}
            handleSelectTags={handleSelectTags}
            handleEnterTags={handleEnterTags}
            name="tags"
            control={control}
            currentTags={currentTags ? currentTags : []}
            options={tags ? tags : []}
          />
        </Box>
      </form>
      <Preloader isLoading={isGettingTags} />
    </>
  );
};
