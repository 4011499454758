import { ValidationRule } from "react-hook-form";

export interface IUrlValidation {
  required?: string | ValidationRule<boolean>;
  pattern: {
    message: string;
    value: RegExp;
  };
}

export const urlValidation = (required?: boolean): IUrlValidation => {
  return {
    required: required ? "URL is required" : false,
    pattern: {
      message: "This URL is not valid ",
      value: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    },
  };
};
