import { Typography } from "@material-ui/core";
import React from "react";

interface ISubtitle {
  text: string;
}

export const Subtitle = ({ text }: ISubtitle) => {
  return <Typography variant="subtitle1">{text}</Typography>;
};
