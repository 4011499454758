import React, { useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { MyButton, MyCheckbox, MyDropdown, MyInput, Title } from "components";
import { useEditTextContentMutation, useGetContentPagesQuery } from "api";

import { CONTENT_PAGES } from "constants/text_content_page";
import TextEditor from "components/TextEditor";
interface IFormValues {
  pagePicker: string;
  content: string;
  headline: string;
  isConfirm: boolean;
}

export const TextContent = () => {
  const classes = useStyles();

  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [currentPageId, setCurrentPageId] = useState<number | null>(null);
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
  const { data } = useGetContentPagesQuery();
  const [editTextContent] = useEditTextContentMutation();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<IFormValues>({
    mode: "onChange",
  });

  const fields = watch();
  const toggleConfirm = () => {
    const prevIsConfirm = getValues("isConfirm");
    setValue("isConfirm", !prevIsConfirm);
    setIsConfirm(!isConfirm);
  };

  const handleDropdown = (value: string) => {
    if (value === CONTENT_PAGES.DISCLAIMER || value === CONTENT_PAGES.TERMS) {
      setShowCheckbox(true);
    } else {
      setShowCheckbox(false);
    }
    if (data) {
      const findItem = data.find((i) => i.name === value);
      if (findItem) {
        setValue("headline", findItem.headline);
        setValue("content", findItem.content);
        setValue("isConfirm", findItem.reconfirm);
        setValue("pagePicker", value);
        setIsConfirm(findItem.reconfirm);
        setCurrentPageId(findItem.id);
      }
    }
  };

  const onSubmit = (data: IFormValues) => {
    editTextContent({
      id: currentPageId,
      headline: data.headline,
      content: data.content,
      reconfirm: data.isConfirm,
    })
      .then((res) => data)
      .then((): void => {
        setValue("headline", data.headline);
        setValue("content", data.content);
        setValue("isConfirm", data.isConfirm);
        setValue("pagePicker", data.pagePicker);
        setIsConfirm(data.isConfirm);
      })
      .catch();
  };

  return (
    <>
      <Box mb={2}>
        <Title text="Text Content" />
      </Box>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Box mb={6}>
          <Grid container>
            <Grid item xs={4}>
              <MyDropdown
                options={data}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => handleDropdown(e.target.value)}
                label="Choose Page"
                nmb={true}
                select={true}
                placeholder="Choose Page"
                control={control}
                name="pagePicker"
                rules={{ required: "Page is required" }}
                error={errors.pagePicker}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container xs={12} md={5} xl={4} direction="column">
          <Grid item>
            <MyInput label="Headline" placeholder="Headline" control={control} name="headline" error={errors.headline} />
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: "bold" }}>Content</Typography>
            <Controller
              rules={{ required: "Content can't be empty" }}
              render={({ field }) => <TextEditor {...field} error={errors.content} />}
              name="content"
              control={control}
              defaultValue=""
            />
          </Grid>
          {showCheckbox && (
            <Grid item>
              <MyCheckbox onChange={toggleConfirm} control={control} label="User need to reconfirm" name="isConfirm" checked={isConfirm} />
            </Grid>
          )}
        </Grid>
        <Box mt={4}>
          <MyButton width={150} text="Save" type="submit" />
        </Box>
      </form>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 30,
  },
}));
