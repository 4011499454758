import { useState } from "react";

export const useModalWindow = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>("");
  const openWindow = (name?: string) => {
    setName(name);
    setOpen(true);
  };
  const closeWindow = () => {
    setOpen(false);
    setName("");
  };
  return {
    openWindow,
    closeWindow,
    open,
    name,
  };
};
