import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

interface IPreloader {
  isLoading: boolean;
}

export const Preloader = (props: IPreloader) => {
  const classes = useStyles();
  return (
    <>
      {props.isLoading && (
        <Backdrop className={classes.root} open={props.isLoading}>
          <CircularProgress className={classes.loader} disableShrink />
        </Backdrop>
      )}
    </>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#F8F8F8AD",
    zIndex: 100000,
  },
  loader: {
    left: "50%",
    top: "50%",
    zIndex: 100000,
    position: "absolute",
  },
}));
