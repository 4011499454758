import { IDropdownOption } from "../interfaces/IDropdownOption";

export const START_PAGE_DROPDOWN_VALUES = {
  EXTERNAL_LINK: "EXTERNAL_LINK",
  NEWS: "NEWS",
};
export const START_PAGE_DROPDOWN_OPTIONS = [
  { name: START_PAGE_DROPDOWN_VALUES.EXTERNAL_LINK },
  {
    name: START_PAGE_DROPDOWN_VALUES.NEWS,
    disabled: true,
  },
] as IDropdownOption[];
