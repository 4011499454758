import { isFulfilled, isPending, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

/**
 * Show a toast!
 */

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  const isMutation = action.meta?.arg?.type === "mutation"; // If we want show message only in POST,PUT,DELETE,PATCH METHODS
  //const method = action.meta?.baseQueryMeta?.request?.method;
  const toaster = toast;

  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      localStorage.clear();
    }
    toaster.error(action.payload?.data?.message ? action.payload?.data?.message : "Server Error", {
      id: toaster.name,
    });
  }
  if (isPending(action)) {
    if (isMutation && action.meta.requestStatus === "pending") {
      toaster.loading("loading", {
        id: toaster.name,
      });
    }
  }

  if (isFulfilled(action)) {
    if (isMutation) {
      toaster.success(action?.payload?.message ? action?.payload?.message : "Updated Successfully", {
        id: toaster.name,
      });
    }
  }

  return next(action);
};
