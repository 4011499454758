import axios from "axios";
import { API_URLS } from "config";
import { AuthLoginDto, AuthLoginResponseDto } from "../dto/auth";

// HEADERS.headers.Authorization = 'Bearer ' + user.token;
export const authAPI = {
  login(data: AuthLoginDto) {
    return axios.post<AuthLoginResponseDto>(API_URLS.login, data).then((res) => res.data);
  },
};
