import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      color: "#666666",
    },
    caption: {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#0946C5",
      light: "#000000",
    },
    background: {
      paper: "#F8F9FB",
    },
    text: {
      secondary: "#666666",
    },
    success: {
      main: "#0F8115",
    },
    error: {
      main: "#C51609",
    },
  },
});
