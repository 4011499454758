import { Box, Grid, makeStyles } from "@material-ui/core";
import { AddEditCategoryForm, DeleteModal, GreyText, MyText, Preloader, PriceFundAddToPriceForm, Title } from "../../../components";
import { useAddFundToPriceMutation, useDeleteFundFromPriceMutation, useEditPriceMutation, useGetPriceFundsQuery, useGetPriceQuery } from "../../../api";
import { useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";
import { IPriceFund } from "../../../models";
import { useModalWindow } from "../../../hooks";
import { useNavigate } from "react-router";

export const Price = () => {
  const [fundId, setFundId] = useState<number | null>(null);

  const { name, open, openWindow, closeWindow } = useModalWindow();

  const { id } = useParams();
  const { data: price } = useGetPriceQuery(Number(id));
  const { data, isLoading: isGettingFunds } = useGetPriceFundsQuery(Number(id));
  const [editPrice] = useEditPriceMutation();

  const [deleteFundFromPrice] = useDeleteFundFromPriceMutation();
  const [addFundToPrice] = useAddFundToPriceMutation();

  const onChangePriceName = (name: string) => {
    editPrice({
      id: Number(id),
      name,
    });
  };

  const onAddFundToPrice = (data: number) => {
    addFundToPrice({
      id: Number(id),
      fundId: data,
    });
  };
  const onDeleteFundFromPrice = () => {
    deleteFundFromPrice({
      id: Number(id),
      fundId: Number(fundId),
    });
  };

  return (
    <>
      <Title text={`Category - ${price?.name}`} />
      <Grid container>
        <Grid xs={11} md={7} xl={3} item>
          <AddEditCategoryForm category={price?.name} action={onChangePriceName} isEdit={true} />
        </Grid>
        {data?.price_funds?.length > 0 && (
          <Grid container>
            <Grid xs={11} md={6} xl={2} item>
              <Box mt={6}>
                {data?.price_funds?.map((i: IPriceFund) => (
                  <FundItem
                    deleteAction={() => {
                      openWindow("delete"), setFundId(i.id);
                    }}
                    id={i.id}
                    key={i.id}
                    name={i.name}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid xs={11} md={7} xl={3} item>
            <PriceFundAddToPriceForm mt={60} addAction={onAddFundToPrice} available_funds={data?.available_funds} />
          </Grid>
        </Grid>
        <Preloader isLoading={isGettingFunds} />
        <DeleteModal model="fund prices" open={open} closeWindow={closeWindow} onOk={name === "add" ? onAddFundToPrice : onDeleteFundFromPrice} />
      </Grid>
    </>
  );
};

interface IFundItem {
  name: string;
  id: number;
  deleteAction: () => void;
}

const FundItem: React.FC<IFundItem> = ({ name, id, deleteAction }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box className={classes.item}>
      <MyText text={name} onClick={() => navigate(`${pathname}/fund/${id}`)} />
      <GreyText isPointer={true} onClick={deleteAction} text="delete from category" withUnderline={true} />
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  //FUND_ITEM
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
  },
}));
