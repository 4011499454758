import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URLS } from "config";
import { getFromLocalStorage } from "utils";
import { ITokens } from "models";
import { UpdatePreloadPageFormDto } from "pages/dashboard/Preload";
import { GetPreloadPageCategoryResponse } from "dto/preload_page/get-preload-page.dto";

export const preloadApi = createApi({
  reducerPath: "preloadApi",
  tagTypes: ["PreloadPage", "PreloadPageImages"],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URLS.pages,
    prepareHeaders: (headers) => {
      const tokens = getFromLocalStorage("tokens") as ITokens;
      if (tokens?.accessToken) {
        headers.set("authorization", `Bearer ${tokens?.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getPreload: build.query<GetPreloadPageCategoryResponse, void>({
      query: () => ({ url: `/preload` }),
      providesTags: ["PreloadPage"],
    }),

    updatePreload: build.mutation<UpdatePreloadPageFormDto, any>({
      query: (body) => ({
        url: "/preload",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["PreloadPage"],
    }),
  }),
});

export const { useGetPreloadQuery, useUpdatePreloadMutation } = preloadApi;
