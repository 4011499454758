import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { makeStyles, Theme } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import { useHandleFile } from "hooks";
import { IMAGE } from "constants/imagesConst";
import { useEditContactMutation, useGetContactQuery, useGetAllCountriesQuery } from "api";
import { MyButton, MyDropdown, MyInput, Preloader, Title, MyFilePicker } from "components";
import { ICountry, IPerson } from "../../models";
import { urlValidation } from "../../components/shared/Inputs/validations";
import { ChangeContactsDto } from "dto/contacts/change-contacts-dto.js";
import { FileType } from "types";

interface IFormValues {
  persons: IPerson[];
  country: string;
  twitter: string;
  soundcloud: string;
  linkedin: string;
  youtube: string;
  headline: string;
  url: string;
  mail: string;
  address: string;
  buttonTxt: string;
}
type TImageFS = {
  [x: string]: string | null;
};
type TFile = {
  [x: string]: File | null | string;
};
export const Contact = () => {
  const classes = useStyles();
  const [currentCountryId, setCurrentCountryId] = useState<number | null>(null);

  const { data: countries, isLoading: isGettingCountries } = useGetAllCountriesQuery();
  const { data: contacts, isLoading: isGettingContacts } = useGetContactQuery(currentCountryId, { skip: currentCountryId === null });
  const [editContact] = useEditContactMutation();
  const [imgFile, setImgFile] = useState<TFile>({});
  const [imagesFromServer, setImagesFromServer] = useState<TImageFS>({});
  const [countryImageFS, setCountryImageFS] = useState<string | FileType | null>("");

  const { file: image, handleChangeFile: handleChangeManagerImage } = useHandleFile();

  const handleChangeFile = (file: File | null, index: number): void => {
    const key: string = index.toString();
    const newImgFile: TFile = {};
    newImgFile[key] = file;
    setImgFile((prev) => ({ ...prev, ...newImgFile }));
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      persons: [
        {
          person: "",
          details: "",
          email: "",
          linkedIn: "",
        },
      ],
    },
  });

  const formFields = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "persons",
  });
  const handleRemove = (index: number) => {
    remove(index);

    const key: string = index.toString();

    imgFile[key] = null;
    setImgFile((prev) => ({ ...prev, ...imgFile }));
  };

  const handleImageSet = (image: any) => {
    handleChangeManagerImage(image);
    setCountryImageFS(image);
  };

  const onSubmit = (data: IFormValues) => {
    const formData: any = new FormData();
    const imgFileKeys: string[] = Object.keys(imgFile);
    const array: any[] = [];
    if (imgFileKeys.length > 0) {
      for (const key of imgFileKeys) {
        if (typeof imgFile[key] != "string" && imgFile[key] != null) {
          formData.append(`image_${key}`, imgFile[key], `imagePerson${key}`);
          array.push(imgFile[key]);
        } else {
          array.push(imgFile[key]);
        }
      }
    }
    formData.append(
      "persons",
      JSON.stringify(
        data.persons.map((person: IPerson, index: number) => ({
          person: person.person,
          details: person.details,
          email: person.email,
          id: person.oldId,
          linkedIn: person.linkedIn,
          imageFromServer: array[index],
        })),
      ),
    );

    formData.append(
      "contacts",
      JSON.stringify({
        country: data.country,
        linkedin: data.linkedin,
        soundcloud: data.soundcloud,
        twitter: data.twitter,
        youtube: data.youtube,
        url: data.url,
        headline: data.headline,
        address: data.address,
        buttonTxt: data.buttonTxt,
        mail: data.mail,
        imageFS: countryImageFS,
      }),
    );

    const newPersonsIds = data.persons.map((person) => person?.oldId).filter((val) => typeof val === "number");

    let idsForDelete: (number | undefined)[] = [];

    if (contacts && contacts.persons.length > 0) {
      idsForDelete = contacts.persons.filter((person: IPerson) => typeof person.id === "number" && !newPersonsIds.includes(person.id)).map((person) => person.id);

      formData.append("deletedIds", JSON.stringify(idsForDelete));
    } else {
      formData.append("deletedIds", JSON.stringify([]));
    }
    formData.append("countryId", JSON.stringify(currentCountryId));

    formData.append("countryImage", image);

    editContact(formData);

    setImgFile({});
  };

  useEffect(() => {
    if (contacts) {
      const imagesPersons: TImageFS = {};
      contacts.persons.map((person: IPerson, index: number) => {
        const iKey = index.toString();
        imagesPersons[iKey] = person.image;
      });
      setImagesFromServer(imagesPersons);
      setImgFile((prev) => ({ ...prev, ...imagesPersons }));
      if (contacts.image) {
        setCountryImageFS(contacts.image);
        handleChangeManagerImage(contacts.image);
      }
      const newPersons: IPerson[] | any = contacts.persons.map((person: IPerson | any, index: number) =>
        person.person || person.details || person.email || person.linkedIn || person.image
          ? {
              person: person.person,
              details: person.details,
              oldId: person.id,
              email: person.email,
              linkedIn: person.linkedIn,
              image: imagesFromServer[index],
            }
          : null,
      );
      const newPersonsFiltered = newPersons.filter((element: IPerson) => {
        return element !== null;
      });
      console.log("newPersons", newPersons);
      setValue("persons", newPersonsFiltered);
      setValue("youtube", contacts.youtube);
      setValue("twitter", contacts.twitter);
      setValue("soundcloud", contacts.soundcloud);
      setValue("linkedin", contacts.linkedin);
      setValue("address", contacts.address);
      setValue("headline", contacts.headline);
      setValue("url", contacts.url);
      setValue("mail", contacts.mail);
      setValue("buttonTxt", contacts.buttonTxt);
    } else {
      setValue("persons", [
        {
          person: "",
          details: "",
          email: "",
          linkedIn: "",
          image: "",
        },
      ]);
      setValue("youtube", "");
      setValue("twitter", "");
      setValue("soundcloud", "");
      setValue("linkedin", "");
      setValue("address", "");
      setValue("mail", "");
      setValue("headline", "");
      setValue("url", "");
      setValue("buttonTxt", "");
    }
  }, [contacts]);

  const addPerson = () => {
    append({
      person: "",
      details: "",

      email: "",
      linkedIn: "",
    });
  };

  const handleDropdown = (value: string) => {
    if (countries) {
      const findItem = countries.find((i: ICountry) => i.name === value);
      if (findItem) {
        setCurrentCountryId(findItem.id);
        setValue("country", findItem.name);
      }
    }
  };
  const renderPersons = () => {
    return fields.map((field, index) => (
      <Box mb={4} key={field.id}>
        <Grid container flexDirection="row">
          <Grid item flexDirection="row" xs={5} mr={2}>
            <MyInput placeholder={""} label={`Person - ${index + 1}`} textarea minRows={8} control={control} name={`persons[${index}].person`} />
          </Grid>
          <Grid item flexDirection="row" xs={5} mr={2}>
            <MyInput placeholder={""} label={`Details  Person - ${index + 1}`} textarea minRows={8} control={control} name={`persons[${index}].details`} />
          </Grid>
          <Grid item flexDirection="row" xs={5} mr={2}>
            <MyInput placeholder={"Email"} label={`E-Mail  Person - ${index + 1}`} textarea minRows={2} control={control} name={`persons[${index}].email`} />
          </Grid>
          <Grid item flexDirection="row" xs={5} mr={2}>
            <MyInput placeholder={"LinkedIn"} label={`LinkedIn  Person - ${index + 1}`} textarea minRows={2} control={control} name={`persons[${index}].linkedIn`} />
          </Grid>
          <Grid item xs={4}>
            <Box mb={8}>
              <MyFilePicker file={imgFile[index]} name={`persons[${index}].image`} setFile={(file) => handleChangeFile(file, index)} size={IMAGE.OTHER_SIZE} />
            </Box>
          </Grid>
          <Grid item flexDirection="row">
            {index > 0 && (
              <Typography className={classes.deleteText} onClick={() => handleRemove(index)}>
                delete
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    ));
  };
  return (
    <>
      <Box mb={2}>
        <Title text="Contacts" />
      </Box>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Box mb={5}>
          <Grid container>
            <Grid item xs={4}>
              <MyDropdown
                options={countries}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => handleDropdown(e.target.value)}
                value={formFields.country}
                label="Choose Country"
                nmb={true}
                placeholder="Choose Country"
                control={control}
                name="country"
                rules={{ required: "Country  is required" }}
                error={errors.country}
                select={true}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={3}>
          <Title text="Links to Social Media" />
        </Box>
        <Grid container justifyContent="space-between" mb={4}>
          <Grid item container xs={4}>
            <Grid item xs={12} mb={4}>
              <MyInput label="Link to Twitter" placeholder="URL" control={control} name="twitter" rules={urlValidation(true)} error={errors.twitter} />
            </Grid>
            <Grid item xs={12}>
              <MyInput label="Link to Soundcloud" placeholder="URL" control={control} name="soundcloud" rules={urlValidation(true)} error={errors.soundcloud} />
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item xs={12} mb={4}>
              <MyInput label="Link to LinkedIn" placeholder="URL" control={control} name="linkedin" rules={urlValidation(true)} error={errors.linkedin} />
            </Grid>
            <Grid item xs={12}>
              <MyInput label="Link to YouTube" placeholder="URL" control={control} name="youtube" rules={urlValidation(true)} error={errors.youtube} />
            </Grid>
          </Grid>
        </Grid>
        <Box mb={2}>
          <Title text="General Information" />
        </Box>
        <Box mb={6}>
          <Grid container>
            <Grid item xs={4}>
              <MyFilePicker file={image} setFile={(image) => handleImageSet(image)} size={IMAGE.OTHER_SIZE} />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid container justifyContent="space-between" mb={4}>
            <Grid item xs={4} mb={4}>
              <MyInput label="Headline" placeholder="Headline" control={control} name="headline" error={errors.headline} />
            </Grid>
            <Grid item xs={4}>
              <MyInput label="Button Text" placeholder="Button Text" control={control} name="buttonTxt" error={errors.buttonTxt} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" mb={4}>
            <Grid item xs={4}>
              <MyInput label="Address" control={control} name="address" textarea minRows={8} error={errors.address} />
            </Grid>
            <Grid item direction="column" xs={4}>
              <Grid item>
                <MyInput label="URL" placeholder="URL" control={control} name="url" textarea error={errors.url} />
              </Grid>
              <Grid item>
                <MyInput label="Mail" placeholder="Mail" control={control} name="mail" textarea error={errors.mail} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mb={3}>
          <Title text="Contact Persons" />
        </Box>
        <Grid item container flexDirection="column">
          {renderPersons()}
          <Grid item container justifyContent="center" alignItems="center">
            <MyButton width={150} text="Add Contact" onClick={addPerson} />
          </Grid>
        </Grid>
        <Grid item container>
          <MyButton width={150} text="Save" type="submit" />
        </Grid>
      </form>
      <Preloader isLoading={isGettingContacts || isGettingCountries} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 30,
  },
  mb: {
    paddingBottom: 40,
  },
  deleteText: {
    cursor: "pointer",
    marginLeft: "18px",
    textDecoration: "underline",
    color: "#666666",
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));
