import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

import { AddEditCategoryForm, CategoryItem, DeleteModal, Preloader, Title } from "components";
import { useModalWindow } from "hooks";
import { useGetMediaCategoriesQuery, useCreateMediaCategoryMutation, useDeleteMediaCategoryMutation, useChangeOrderMediaCategoryMutation, useChangeStatusMediaCategoryMutation } from "api";

import { ChangeOrderPriceDto, ChangeStatusPriceDto } from "dto";

export const Media = () => {
  const [currentPriceId, setCurrentPriceId] = useState<number | null>(null);

  const { data: mediaCategories, isLoading } = useGetMediaCategoriesQuery();
  const [addCategory, { isLoading: isAdding }] = useCreateMediaCategoryMutation();
  const [deletePrice] = useDeleteMediaCategoryMutation();
  const [changeStatus] = useChangeStatusMediaCategoryMutation();
  const [changeOrder] = useChangeOrderMediaCategoryMutation();

  const { open, openWindow, closeWindow } = useModalWindow();

  const onChangeStatus = (data: ChangeStatusPriceDto) => {
    changeStatus(data);
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder(data);
  };

  const onDelete = () => {
    if (currentPriceId) {
      deletePrice(currentPriceId);
    }
  };

  return (
    <>
      <Box mb={2}>
        <Title text="Media - Category" />
      </Box>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          {mediaCategories &&
            mediaCategories.map((i, index: number) => (
              <CategoryItem
                openWindow={openWindow}
                changeStatusAction={onChangeStatus}
                deleteAction={(id: number) => setCurrentPriceId(id)}
                changeOrderAction={onChangeOrder}
                lastIndex={mediaCategories.length - 1}
                id={i.id}
                index={index}
                key={i.id}
                name={i.name}
                isStatus={true}
                status={i.status}
                isArrows={true}
                nextPath={`/dashboard/media/${i.id}`}
              />
            ))}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <AddEditCategoryForm action={(name: string) => addCategory({ name })} />
        </Grid>
      </Grid>
      <DeleteModal model={"media"} open={open} closeWindow={closeWindow} onOk={onDelete} />
      <Preloader isLoading={isLoading || isAdding} />
    </>
  );
};
