import { createSlice } from "@reduxjs/toolkit";
import { login } from "../thunks/auth";
import { ITokens } from "../../models/ITokens";
import { IUser } from "../../models/IUser";

interface AuthState {
  error: string | null;
  isLoading: boolean;
  user: IUser | null;
  tokens: ITokens | null;
}

const initialState: AuthState = {
  isLoading: false,
  error: null,
  user: null,
  tokens: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state: AuthState) {
      state.tokens = null;
      state.user = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [login.pending.type]: (state) => {
      state.isLoading = true;
    },
    [login.fulfilled.type]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("tokens", JSON.stringify(action.payload.tokens));
      state.tokens = action.payload.tokens;
      state.user = action.payload.user;
      state.error = null;
      state.isLoading = false;
    },
    [login.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { actions } = authSlice;
// Extract and export each action creator by name
export const { logout } = actions;
