import { useForm } from "react-hook-form";
import { MyButton, MyDropdown } from "../shared";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { IPriceFund } from "../../models";

interface IPriceFundAddToPriceForm {
  available_funds: IPriceFund[];
  addAction: (fundId: number) => void;
  mt?: number; //margin top
}

interface FormValues {
  fund?: string;
}

export const PriceFundAddToPriceForm: React.FC<IPriceFundAddToPriceForm> = ({ mt, addAction, available_funds }) => {
  const classes = useStyles({ mt });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<FormValues>({ mode: "onChange" });

  const fields = watch();
  const onSubmit = (data: FormValues) => {
    const fund = available_funds.find((i) => i.name === data.fund);
    if (fund) {
      addAction(fund.id);
    }
    setValue("fund", undefined);
  };
  const handleChange = (e: any) => {
    setValue("fund", e.target.value);
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <MyDropdown
        options={available_funds}
        label="Add Fund to Category"
        nmb={true}
        placeholder="Category Name"
        control={control}
        name="fund"
        value={fields.fund}
        rules={{ required: "Fund  is required" }}
        error={errors.fund}
        select={true}
        onClick={handleChange}
      />
      <MyButton text="Save" type="submit" />
    </form>
  );
};

const useStyles = makeStyles(() => ({
  root: (props: { mt?: number }) => ({
    display: "flex",
    alignItems: "center",
    marginTop: props.mt ? props.mt : 100,
    marginBottom: 30,
    "& button": {
      marginLeft: 20,
    },
  }),
}));
