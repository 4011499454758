import { Box, Grid } from "@material-ui/core";
import { useAddPriceMutation, useChangeOrderPriceMutation, useChangeStatusPriceMutation, useDeletePriceMutation, useGetPricesQuery } from "../../../api";
import { AddEditCategoryForm, CategoryItem, DeleteModal, Preloader, Title } from "../../../components";
import { IPrice } from "../../../models";
import { ChangeOrderPriceDto, ChangeStatusPriceDto } from "../../../dto";
import { useModalWindow } from "../../../hooks";
import React, { useState } from "react";

export const Prices = () => {
  const [currentPriceId, setCurrentPriceId] = useState<number | null>(null);
  const { data, isLoading } = useGetPricesQuery();
  const [addPrice] = useAddPriceMutation();
  const [deletePrice] = useDeletePriceMutation();
  const [changeStatus] = useChangeStatusPriceMutation();
  const [changeOrder] = useChangeOrderPriceMutation();

  const { open, openWindow, closeWindow } = useModalWindow();

  const onDelete = () => {
    if (currentPriceId) {
      deletePrice(currentPriceId);
    }
  };
  const onChangeStatus = (data: ChangeStatusPriceDto) => {
    changeStatus(data);
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder(data);
  };

  if (isLoading) return <Preloader isLoading={isLoading} />;
  return (
    <>
      <Box mb={2}>
        <Title text="Category" />
      </Box>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          {data &&
            data.map((i: IPrice, index: number) => (
              <CategoryItem
                openWindow={openWindow}
                changeStatusAction={onChangeStatus}
                deleteAction={(id: number) => setCurrentPriceId(id)}
                changeOrderAction={onChangeOrder}
                lastIndex={data.length - 1}
                id={i.id}
                index={index}
                key={i.id}
                name={i.name}
                isStatus={true}
                status={i.status}
                isArrows={true}
              />
            ))}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <AddEditCategoryForm action={(name) => addPrice({ name })} />
        </Grid>
      </Grid>

      <DeleteModal model={"fund prices"} open={open} closeWindow={closeWindow} onOk={onDelete} />
    </>
  );
};
