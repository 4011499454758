import { Typography } from "@material-ui/core";
import React from "react";

interface IText {
  text: string;
  onClick?: () => void;
}

export const MyText: React.FC<IText> = ({ text, onClick }) => {
  return (
    <Typography style={{ cursor: onClick && "pointer" }} onClick={onClick} noWrap={true}>
      {text}
    </Typography>
  );
};
