import { Grid, makeStyles, Typography } from "@material-ui/core";
import { MyButton, MyInput, Preloader, Subtitle, Title } from "../../../components";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEditPaletteFundMutation, useGetPaletteFundQuery } from "../../../api";
import { colorsData, fundData } from "../../../constants/fund_palette";
import { useHandleFile } from "../../../hooks";
import { useNavigate } from "react-router";
import TextEditor from "components/TextEditor";

export const PaletteFund = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { data, isLoading } = useGetPaletteFundQuery(id);
  const [editFund] = useEditPaletteFundMutation();

  const { file: managerImage, handleChangeFile: handleChangeManagerImage } = useHandleFile();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    for (const prop in data) {
      setValue(prop, data[prop]);
    }
    if (data?.manager_image) {
      handleChangeManagerImage(data.manager_image);
    }
  }, [id, data]);

  const onSubmit = (data: any) => {
    const formData = new FormData();
    for (const prop in data) {
      if (data[prop]) {
        formData.append(prop, data[prop]);
      }
    }
    editFund({
      id,
      data: formData,
    });
    navigate(`/dashboard/palettes`);
  };

  if (isLoading) {
    return <Preloader isLoading={true} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Title text={`${data?.name}`} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Subtitle text="Fund Colors" />
          {colorsData.map((c) => (
            <MyInput
              key={c.label}
              placeholder={c.label}
              control={control}
              label={c.label}
              name={c.name}
              rules={{
                required: `${c.label} is required`,
                pattern: {
                  message: "This is not valid HEX color",
                  value: /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
                },
              }}
              error={errors[c.name]}
            />
          ))}
        </Grid>
        <Grid item md={2} />
        <Grid item xs={12} md={4}>
          <Subtitle text="Fund Information" />
          {fundData.map((c) =>
            c.name !== "strategy" ? (
              <MyInput
                key={c.label}
                minRows={5}
                textarea={c.isTextarea}
                placeholder={c.label}
                control={control}
                label={c.label}
                name={c.name}
                rules={c.name === "headline" ? { required: false } : { required: `${c.label} name is required` }}
                error={errors[c.name]}
              />
            ) : (
              <>
                <Typography style={{ fontWeight: "bold" }}>Strategy - Page</Typography>
                <Controller
                  rules={{ required: `${c.label} name is required` }}
                  render={({ field }) => <TextEditor {...field} error={errors.strategy} />}
                  name="strategy"
                  control={control}
                  defaultValue=""
                />
              </>
            ),
          )}
          <Subtitle text="Fund Manager" />
          <MyInput control={control} placeholder={"Name"} label="Name" name="manager" error={errors.manager} />
        </Grid>
      </Grid>

      <MyButton text="Save" type="submit" />
    </form>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "& h6": {
      marginBottom: 35,
    },
  },
}));
