import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import moment from "moment";

import { useAppSelector } from "redux/hooks";
import { Title, MyFilePicker, MyButton, MyInput, DatePicker, TimePicker } from "components";
import { useHandleFile } from "hooks";
import { IMAGE } from "constants/imagesConst";
import { urlValidation } from "components/shared/Inputs/validations";
import { useCreateEventMutation } from "api";
import TextEditor from "components/TextEditor";

interface IFormValues {
  name: string;
  date: string;
  location: string;
  description: string;
  url: string;
}

export const EventDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const eventName = useAppSelector(({ events }) => events.name);

  const { file: image, handleChangeFile: handleChangeManagerImage } = useHandleFile();
  const [createEvent] = useCreateEventMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      name: "",
      date: "",
      location: "",
      description: "",
      url: "",
    },
  });

  useEffect(() => {
    if (eventName) {
      setValue("name", eventName);
    }
  }, [eventName]);

  const onSubmit = (data: any) => {
    const formData: any = new FormData();
    formData.append("image", image);
    formData.append("name", data.name);
    formData.append("date", data.date);
    formData.append("location", data.location);
    formData.append("descriptionText", data.description);
    formData.append("url", data.url);

    createEvent(formData).then(() => {
      reset({
        name: "",
        date: "",
        location: "",
        description: "",
        url: "",
      });
      handleChangeManagerImage(null);
      navigate(`/dashboard/events`);
    });
  };

  return (
    <>
      <Box mb={2}>
        <Title text="Event" />
      </Box>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Box mb={5}>
          <Grid container>
            <Grid item xs={4}>
              <MyFilePicker file={image} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="space-between" mb={4}>
          <Grid item container xs={4}>
            <Grid item xs={12} mb={2}>
              <MyInput label="Name" placeholder="name" control={control} name="name" rules={{ required: "Name is required" }} error={errors.name} />
            </Grid>
            <Grid item xs={12} mb={2}>
              <MyInput label="Date" placeholder="Date" control={control} name="date" rules={{ required: "Date is required" }} error={errors.date} />
            </Grid>
            <Grid item xs={12} mb={2}>
              <MyInput label="Location" placeholder="Location" control={control} name="location" rules={{ required: "Location is required" }} error={errors.location} />
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography style={{ fontWeight: "bold" }}>Description</Typography>
              <Controller
                rules={{ required: "Description can't be empty" }}
                render={({ field }) => <TextEditor {...field} error={errors.description} />}
                name="description"
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <MyInput label="URL" placeholder="URL" control={control} name="url" rules={urlValidation(true)} error={errors.url} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <MyButton disabled={false} width={150} text="Save" type="submit" />
        </Grid>
      </form>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 30,
  },
}));
