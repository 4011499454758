import React from "react";
import { Autocomplete, Chip } from "@mui/material";
import { Box, TextField, Typography } from "@material-ui/core";
import { ITextField } from "./MyDropdown";
import { Controller } from "react-hook-form";
import { IDropdownOption } from "interfaces/IDropdownOption";
import { inputStyles } from "components/shared/Inputs/styles";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { StartPageCategoryFormValues } from "pages/dashboard/startPage/StartPageCategory";
import ClearIcon from "@mui/icons-material/Clear";
import { v4 as uuidv4 } from "uuid";

interface IMyTags extends ITextField {
  options: IDropdownOption[] | [];
  currentTags: IDropdownOption[] | [];
  placeholder?: string;
  handleSelectTags: (newTag: IDropdownOption | null, isDelete?: boolean, isClear?: boolean) => void;
  handleEnterTags: (value: IDropdownOption) => void;
  updateValue: UseFormSetValue<StartPageCategoryFormValues> | any;
}

export const MyTags: React.FC<IMyTags> = ({ handleEnterTags, handleSelectTags, updateValue, options, currentTags, placeholder, name, control }) => {
  const classes = inputStyles({ autocomplete: true });

  const handleChange = (item: string, isDelete?: boolean) => {
    const [selectItem] = options.filter((o) => o.name === item);

    const _isDelete = isDelete ? isDelete : currentTags.find((i) => i.id === selectItem.id);

    if (!selectItem) {
      handleSelectTags({ name: item, id: -1 }, Boolean(_isDelete));
      updateValue("tags", "value");
    }
    handleSelectTags(selectItem, Boolean(_isDelete));
    updateValue("tags", "value");
  };

  const createNewTag = (targetValue: string) => {
    const newId = uuidv4();
    handleSelectTags({
      id: newId,
      name: targetValue,
      isNew: true,
    });
    updateValue("tags", "value");
  };

  const handleBlur = (e: any) => {
    if (e.target.value) {
      createNewTag(e.target.value);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.target.value) {
      createNewTag(e.target.value);
    }
  };

  const handleClearAll = () => {
    updateValue("tags", "");
    handleSelectTags(null, undefined, true);
  };

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        defaultValue={currentTags.map((i: IDropdownOption) => i.name)}
        render={({ field }) => (
          <Autocomplete
            limitTags={2}
            multiple
            clearIcon={<ClearIcon onClick={handleClearAll} fontSize="small" />}
            options={options.map((option) => option.name)}
            value={currentTags.map((ct) => ct.name)}
            defaultValue={currentTags.map((ct) => ct.name)}
            freeSolo={true}
            renderOption={(props, option) => (
              <Box
                {...props}
                onClick={(e: any) => {
                  props.onClick && props.onClick(e);
                  handleChange(option);
                }}
                style={{ width: "100%" }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={{ width: "50%" }}>{option}</Typography>
              </Box>
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Box key={index}>
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={(e) => {
                      getTagProps({ index }).onDelete(e);
                      handleChange(option, true);
                    }}
                  />
                </Box>
              ))
            }
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            renderInput={(params) => (
              <TextField
                required={currentTags.length <= 0}
                {...field}
                {...params}
                classes={{ root: classes.textField }}
                variant="outlined"
                label=""
                placeholder={placeholder ? placeholder : "Tags"}
              />
            )}
          />
        )}
      />
    </Box>
  );
};
