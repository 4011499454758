import { Box, Grid } from "@material-ui/core";
import { AddEditCategoryForm, CategoryItem, DeleteModal, Preloader, Title } from "../../../components";
import { useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";
import { useModalWindow } from "hooks";
import {
  useAddFundToPaletteMutation,
  useChangeOrderFundPaletteMutation,
  useChangeStatusFundPaletteMutation,
  useDeleteFundFromPaletteMutation,
  useEditPaletteMutation,
  useGetPaletteFundsQuery,
  useGetPaletteQuery,
} from "api";
import { IPalette } from "models";
import { ChangeOrderPriceDto, ChangeStatusPriceDto } from "dto";

export const Palette = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const thisId = Number(id);

  const [fundId, setFundId] = useState<number | null>(null);

  const { name, open, openWindow, closeWindow } = useModalWindow();

  const { data: palette } = useGetPaletteQuery(thisId);
  const { data: funds } = useGetPaletteFundsQuery(thisId);

  const [editPalette, { isLoading: isEditingPalette }] = useEditPaletteMutation();

  const [deleteFundFromPalette] = useDeleteFundFromPaletteMutation();
  const [addFundToPalette] = useAddFundToPaletteMutation();
  const [changeStatus] = useChangeStatusFundPaletteMutation();
  const [changeOrder] = useChangeOrderFundPaletteMutation();

  const onChangePaletteName = (name: string) => {
    editPalette({
      id: thisId,
      name: name,
    });
  };

  const onAddFundToPalette = (name: string) => {
    addFundToPalette({
      id: thisId,
      name: name,
    });
  };

  const onDeleteFundFromPalette = () => {
    deleteFundFromPalette({
      id: thisId,
      fundId: Number(fundId),
    });
  };
  const onChangeStatus = (data: ChangeStatusPriceDto) => {
    changeStatus(data);
  };

  const onChangeOrder = (data: ChangeOrderPriceDto) => {
    changeOrder({
      direction: data.direction,
      mainId: thisId,
      secondaryId: data.mainId,
    });
  };

  if (isEditingPalette) {
    return <Preloader isLoading={true} />;
  }
  return (
    <>
      <Title text={`Category - ${palette?.name}`} />
      <Grid container>
        <Grid xs={11} md={7} xl={3} item>
          <AddEditCategoryForm category={palette?.name} action={onChangePaletteName} isEdit={true} />
        </Grid>
      </Grid>
      {funds?.length > 0 && (
        <Box mt={5}>
          <Grid container>
            <Grid xs={11} md={7} xl={3} item>
              {funds.map((i: IPalette, index: number) => (
                <CategoryItem
                  openWindow={openWindow}
                  changeStatusAction={onChangeStatus}
                  deleteAction={(id: number) => setFundId(id)}
                  changeOrderAction={onChangeOrder}
                  lastIndex={funds?.length - 1}
                  id={i.id}
                  index={index}
                  key={i.id}
                  name={i.name}
                  isStatus={true}
                  status={i.status}
                  isArrows={true}
                  nextPath={`${pathname}/fund/${i.id}`}
                  isEdit
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container>
        <Grid xs={11} md={7} xl={3} item>
          <AddEditCategoryForm btnText="Add Fund" placeholder="Add Fund" action={onAddFundToPalette} isEdit={true} />
        </Grid>
      </Grid>

      <DeleteModal model="fund palette" open={open} closeWindow={closeWindow} onOk={name === "add" ? onAddFundToPalette : onDeleteFundFromPalette} />
    </>
  );
};
