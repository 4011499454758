import { Box } from "@material-ui/core";
import React from "react";
import Nordea_logo from "components/shared/svg/nordea_logo.svg";
import Rio_logo from "components/shared/svg/riomobile_logo.svg";

export const Nordea = () => {
  return (
    <Box>
      <img src={Nordea_logo} alt="logo" />
    </Box>
  );
};
export const Rio = () => {
  return (
    <Box>
      <img src={Rio_logo} alt="logo" />
    </Box>
  );
};
