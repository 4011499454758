import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { MyText } from "./shared/Typography/MyText";
import { ArrowIcon } from "./ArrowIcon";
import { GreyText } from "./shared/Typography/GreyText";
import { useNavigate } from "react-router-dom";
import { IPriceFundLink } from "../models/IPrice";
import { ChangeStatusPriceDto } from "../dto/prices/change-status-price.dto";
import { ChangeOrderPriceDto } from "../dto/prices/change-order-price.dto";
import { CategoryDirection } from "../enums/CategoryDirection";

export interface ICategoryItem {
  id: number;
  status?: boolean;
  name: string;
  isEdit?: boolean;
  isStatus?: boolean;
  isArrows?: boolean;
  index: number;
  lastIndex: number;
  notBorders?: boolean;
  changeStatusAction: (data: ChangeStatusPriceDto) => void;
  changeOrderAction: (data: ChangeOrderPriceDto) => void;
  deleteAction: (id: number) => void;
  editAction?: (data: IPriceFundLink) => void;
  openWindow: (name: string) => void;
  nameIsNotClickable?: boolean;
  nextPath?: string;
}

export const CategoryItem: React.FC<ICategoryItem> = ({
  id,
  lastIndex,
  index,
  name,
  status,
  changeStatusAction,
  changeOrderAction,
  deleteAction,
  openWindow,
  isStatus,
  notBorders,
  isArrows,
  isEdit,
  nameIsNotClickable,
  nextPath,
}) => {
  const classes = useStyles({ notBorders });
  const navigate = useNavigate();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        {nameIsNotClickable ? <MyText text={name} /> : <MyText text={name} onClick={() => navigate(nextPath ? nextPath : `${id}`)} />}
      </Grid>

      {isStatus && (
        <Grid item xs={2}>
          <Status id={id} active={status} changeStatusAction={changeStatusAction} />
        </Grid>
      )}

      {isArrows && (
        <Grid item xs={2}>
          <Box display="flex" alignItems="center">
            {lastIndex !== index && (
              <Box
                onClick={() =>
                  changeOrderAction({
                    mainId: id,
                    direction: CategoryDirection.NEXT,
                  })
                }
              >
                <ArrowIcon />
              </Box>
            )}
            {index !== 0 && (
              <Box
                onClick={() =>
                  changeOrderAction({
                    mainId: id,
                    direction: CategoryDirection.PREV,
                  })
                }
              >
                <ArrowIcon up={true} />
              </Box>
            )}
          </Box>
        </Grid>
      )}

      <Grid item xs={2}>
        <Box display="flex" alignItems="center">
          <Grid item xs={6}>
            {isEdit && <GreyText align="center" onClick={() => navigate(nextPath ? nextPath : `${id}`)} isPointer={true} withUnderline={true} text="edit" />}
          </Grid>
          <Grid item xs={6}>
            <GreyText
              onClick={() => {
                openWindow("delete"), deleteAction(id);
              }}
              isPointer={true}
              align="right"
              withUnderline={true}
              text={"delete"}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export interface IStatus {
  active?: boolean;
  id: number;
  changeStatusAction: (data: ChangeStatusPriceDto) => void;
}

export const Status: React.FC<IStatus> = ({ changeStatusAction, id, active }) => {
  const classes = useStyles({ notBorders: false });
  return (
    <Typography
      onClick={() =>
        changeStatusAction({
          id,
          new_status: !active,
        })
      }
      className={clsx(active ? classes.status : classes.statusInActive)}
    >
      {active ? "active" : "inactive"}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { notBorders?: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: props.notBorders ? "11px 0" : "21px 0",
    borderTop: props.notBorders ? "" : `1px solid ${theme.palette.text.secondary}`,
    "&:last-child": {
      borderBottom: props.notBorders ? "" : `1px solid ${theme.palette.text.secondary}`,
    },
  }),
  status: {
    cursor: "pointer",
    color: theme.palette.success.main,
  },
  statusInActive: {
    cursor: "pointer",
    color: theme.palette.error.main,
  },
}));
