import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { contactsApi, countriesApi, esg_pageApi, media_pageApi, newsApi, paletteFundsApi, priceFundsApi, start_pageApi, tagApi, textContentApi, preloadApi, eventsApi } from "api";

import { authSlice } from "./slices/authSlice";
import { newsSlice } from "redux/slices/newsSlice";
import { eventsSlice } from "redux/slices/eventsSlice";
import { rtkQueryErrorLogger } from "redux/middlewares/errorMiddleware";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  news: newsSlice.reducer,
  events: eventsSlice.reducer,
  [priceFundsApi.reducerPath]: priceFundsApi.reducer,
  [paletteFundsApi.reducerPath]: paletteFundsApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [tagApi.reducerPath]: tagApi.reducer,
  [textContentApi.reducerPath]: textContentApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [start_pageApi.reducerPath]: start_pageApi.reducer,
  [esg_pageApi.reducerPath]: esg_pageApi.reducer,
  [media_pageApi.reducerPath]: media_pageApi.reducer,
  [preloadApi.reducerPath]: preloadApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setUpStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddiware) =>
      getDefaultMiddiware().concat(
        start_pageApi.middleware,
        priceFundsApi.middleware,
        paletteFundsApi.middleware,
        countriesApi.middleware,
        newsApi.middleware,
        tagApi.middleware,
        textContentApi.middleware,
        contactsApi.middleware,
        esg_pageApi.middleware,
        media_pageApi.middleware,
        preloadApi.middleware,
        eventsApi.middleware,
        rtkQueryErrorLogger,
      ),
  });
export const store = setUpStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setUpStore>;
export type AppDispatch = AppStore["dispatch"];

setupListeners(store.dispatch);
