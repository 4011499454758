import { useForm } from "react-hook-form";
import { MyButton, MyInput } from "../shared";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { IPriceFundLink } from "../../models";
import { urlValidation } from "../shared/Inputs/validations";

interface IPriceFundLinkForm {
  action: (data: IPriceFundLink) => void;
  isEdit?: boolean;
  link?: IPriceFundLink | null;
}

interface PriceFundLinkFormValues {
  text?: string;
  url?: string;
}

export const PriceFundLinkForm: React.FC<IPriceFundLinkForm> = ({ action, isEdit, link }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    control,
  } = useForm<PriceFundLinkFormValues>({ mode: "onChange" });

  useEffect(() => {
    if (isEdit) {
      setValue("text", link?.text);
      setValue("url", link?.url);
    }
  }, []);
  const onSubmit = (data: any) => {
    if (isEdit) {
      if (link) {
        action({
          ...data,
          id: link.id,
        });
      }
    } else {
      action(data);
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <MyInput label="Link text" placeholder="Link text" control={control} name="text" rules={{ required: "Text is required" }} error={errors.text} />
      <MyInput label="Link URL" placeholder="Link URL" control={control} name="url" rules={urlValidation(true)} error={errors.url} />
      <MyButton width={150} text="Save" type="submit" disabled={!isValid} />
    </form>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 30,
  },
}));
