import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useChangeStatusMutation, useDelNewsMutation, useGetNewsPaginateQuery, useGetNewsQuery } from "api";
import { NewsItem } from "components/NewsItem";
import { MyButton, MyDropdown, Title } from "components";
import { INews } from "models/INews";
import { useNavigate } from "react-router";
import { news } from "redux/slices/newsSlice";
import _ from "lodash";

const LIMIT = 20;

export const News = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [changeStatus] = useChangeStatusMutation();
  const [page, setPage] = useState<number>(1);
  const { data } = useGetNewsPaginateQuery({ page, limit: LIMIT });
  const [newsItem, setNewsItem] = useState<INews[]>([]);
  const [delNews] = useDelNewsMutation();

  useEffect(() => {
    if (data?.items && data?.items.length > 0) {
      setNewsItem([...newsItem, ...data.items]);
    }
  }, [data]);

  const classes = useStyles();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({ mode: "onChange" });

  const fields = watch();

  const onSubmit = (data: any) => {
    dispatch(news(data.news));
    navigate(`/dashboard/news/details`);
  };

  const onChangeStatus = (id: number, status: boolean) => {
    changeStatus({
      id,
      status,
    }).then(() => {
      setNewsItem(
        newsItem.map((e) => {
          if (e.id === id) {
            return {
              ...e,
              status,
            };
          }
          return e;
        }),
      );
    });
  };

  const handleDelete = (id: number) => {
    delNews(id).then(() => {
      const newsWithoutLastFetch = page >= 1 ? newsItem.slice(0, LIMIT * page - 1) : [];
      const filteredNews = newsWithoutLastFetch.filter((e) => e.id !== id);
      setNewsItem(filteredNews);
    });
  };

  return (
    <Grid container>
      <Box mb={2}>
        <Title text="News" />
      </Box>
      <Grid container>
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <MyDropdown
              options={[
                {
                  name: "Video",
                  id: 1,
                },
                {
                  name: "Podcast",
                  id: 2,
                },
                {
                  name: "Insight",
                  id: 3,
                },
              ]}
              label=""
              nmb={true}
              placeholder="-Type-"
              select={true}
              control={control}
              name="news"
              value={fields.news}
              rules={{ required: "Type of news  is required" }}
              error={errors.news}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) => setValue("news", e.target.value)}
            />
            <MyButton text="Add news" type="submit" />
          </form>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={11} xl={4} style={{ marginTop: "3rem" }}>
          {newsItem.map((n) => (
            <NewsItem
              id={n.id}
              headline={n.headline}
              createdDate={n.createdDate}
              status={n.status}
              text={n.text}
              changeStatusAction={onChangeStatus}
              onDelete={() => handleDelete(n.id)}
              key={n.id}
            />
          ))}
          {page !== data?.meta.totalPages && (
            <Grid className={classes.loadMore}>
              <MyButton
                text="Load More"
                type="submit"
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    alignItems: "center",
    marginTop: 59,
    "& button": {
      marginLeft: 20,
    },
  },
  rotate: {
    transform: "rotate(180deg)",
  },
  loadMore: {
    textAlign: "center",
    marginTop: "2rem",
  },
}));
