import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_URLS } from "config";
import { IEventsResponse, ITokens, IEvent, updateEvent } from "models";
import { ChangeOrderPriceDto, createEvent } from "dto";

import { getFromLocalStorage } from "../utils";

export const eventsApi = createApi({
  reducerPath: "eventsApi",
  tagTypes: ["Events", "Event"],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URLS.events,
    prepareHeaders: (headers) => {
      const tokens = getFromLocalStorage("tokens") as ITokens;
      if (tokens?.accessToken) {
        headers.set("authorization", `Bearer ${tokens?.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getEvents: build.query<IEventsResponse, { page: number; limit: number }>({
      query: ({ page = 1, limit = 20 }) => ({ url: `/all?page=${page}&limit=${limit}` }),
      providesTags: ["Events"],
    }),
    fetchEvent: build.query<IEvent, number>({
      query: (id) => ({ url: `/${id}` }),
      providesTags: ["Event"],
    }),
    delEvent: build.mutation<IEvent, number>({
      query: (id) => ({
        url: `/?eventsId=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    changeEventStatus: build.mutation<IEvent, Partial<IEvent>>({
      query: (body) => ({
        url: `change_status?eventsId=${body.id}&new_status=${Number(body.status)}`,
        method: "PUT",
      }),
    }),
    updateEvent: build.mutation<updateEvent, FormData>({
      query: (body) => ({
        url: `/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Event", "Events"],
    }),
    createEvent: build.mutation<createEvent, createEvent>({
      query: (body) => ({
        url: ``,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    changeOrderEvent: build.mutation<void, ChangeOrderPriceDto>({
      query: (body: ChangeOrderPriceDto) => ({
        url: `change_order?eventsId=${body.mainId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
  }),
});

export const { useGetEventsQuery, useCreateEventMutation, useChangeEventStatusMutation, useDelEventMutation, useFetchEventQuery, useUpdateEventMutation, useChangeOrderEventMutation } =
  eventsApi;
