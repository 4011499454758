import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface ILabel {
  text: string;
  align?: "center" | "inherit" | "justify" | "left" | "right";
}

export const Label: React.FC<ILabel> = ({ text, align }) => {
  const classes = useStyles();
  return (
    <Typography align={align ? align : "left"} className={classes.root}>
      {text}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 7,
  },
}));
