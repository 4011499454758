import { INavigation } from "../interfaces/INavigation";

export const NAVIGATION_ITEMS = [
  {
    name: "Start page",
    link: "/dashboard/start",
  },
  {
    name: "Fund Prices",
    link: "/dashboard/prices",
  },
  {
    name: "Fund Palette",
    link: "/dashboard/palettes",
  },
  {
    name: "Textcontent",
    link: "/dashboard/text_content",
  },
  {
    name: "News",
    link: "/dashboard/news",
  },
  {
    name: "Contact",
    link: "/dashboard/contact",
  },
  {
    name: "Preload",
    link: "/dashboard/preload",
  },
  {
    name: "ESG",
    link: "/dashboard/esg",
  },
  {
    name: "Media",
    link: "/dashboard/media",
  },
  {
    name: "Events",
    link: "/dashboard/events",
  },
  {
    name: "Push",
    link: "/dashboard/push",
  },
] as INavigation[];
