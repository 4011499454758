import React from "react";
import { Breadcrumbs, makeStyles } from "@material-ui/core";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLocation } from "react-router";
import { useMyRoutes } from "../hooks/useMyRoutes";

export const MyBreadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  const routes = useMyRoutes();
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
  });

  return (
    <Breadcrumbs className={classes.root}>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Link key={match.pathname} to={match.pathname} className={clsx(classes.link, location.pathname === match.pathname && classes.disabled)}>
          {breadcrumb}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 30,
  },
  link: {
    textTransform: "lowercase",
    fontWeight: 700,
    fontSize: 16,
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  disabled: {
    color: "grey !important",
  },
}));
