import { Box, makeStyles } from "@material-ui/core";
import React from "react";

export const Layout = (props: any) => {
  const classes = useStyles();
  return <Box className={classes.root}>{props.children}</Box>;
};

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    marginLeft: 100,
    marginRight: 100,
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 30,
    paddingBottom: 30,
    background: "white",
    minHeight: "100%",
    height: "max-content",
  },
}));
