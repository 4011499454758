import { Typography } from "@material-ui/core";
import React from "react";

interface ITitle {
  text: string;
}

export const Title = ({ text }: ITitle) => {
  return <Typography variant="caption">{text}</Typography>;
};
