import React, { useCallback, useEffect, useState } from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";

import toast from "react-hot-toast";
import { API_URLS } from "config";
import { GreyText } from "./shared/Typography/GreyText";

interface IMyMP3Picker {
  file: any | string | null;
  setFile: (file: File | null) => void;
  name?: string;
}

export const MyMP3Picker: React.FC<IMyMP3Picker> = ({ file, setFile }) => {
  const classes = useStyles();
  const [MP3, setMP3] = useState<string>("");

  useEffect(() => {
    if (file && file?.type === "audio/mpeg") {
      setMP3(URL.createObjectURL(file));
    }
    if (typeof file === "string") {
      const url = API_URLS.base + `${file}`;
      setMP3(url);
    }
  }, [file]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("Can not set mp3 file");
      } else {
        setFile(acceptedFiles[0]);
      }
    },
    [file],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 52428800,
    accept: [".mp3"],
  });

  const removeFile = () => {
    setFile(null);
    setMP3("");
  };

  const renderFile = () => {
    return (
      <Grid className={classes.fileContainer}>
        {!file ? (
          <Box display="flex" flexDirection="column">
            <GreyText text="Required file format: mp3" />
          </Box>
        ) : (
          <>
            <Typography>{file?.path ? file?.path : file}</Typography>
            <Typography className={classes.deleteText} onClick={removeFile}>
              delete
            </Typography>
          </>
        )}
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6} xl={4} {...getRootProps()} className={classes.input}>
        <input {...getInputProps()} />
        <Typography className={classes.btnText} variant="subtitle2">
          Choose file
        </Typography>
      </Grid>
      <Grid item xs={6} xl={8}>
        {renderFile()}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& h6": {
      marginBottom: "0px !important",
    },
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  input: {
    height: 40,
    width: 180,
    borderRadius: 6,
    border: "1px solid #C0C0C0",
    backgroundColor: "#E3E6EC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #E3E6EC",
      background: "#EFF2F9",
    },
  },
  deleteText: {
    cursor: "pointer",
    marginLeft: "18px",
    textDecoration: "underline",
    color: "#666666",
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
  btnText: {
    color: "#4b4b4b",
    fontSize: "15px",
    lineHeight: "19px",
  },
}));
