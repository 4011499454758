import { useState } from "react";
import { FileType } from "../types";

export const useHandleMP3 = () => {
  const [file, setFile] = useState<FileType>(null);

  const handleChangeFile = (file: FileType) => {
    setFile(file);
  };

  return {
    file,
    handleChangeFile,
  };
};
