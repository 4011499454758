import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import { inputStyles } from "./styles";
import { Control, Controller } from "react-hook-form";
import React from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { IDropdownOption } from "../../../interfaces/IDropdownOption";
import clsx from "clsx";

export interface ITextField {
  name: string;
  error?: any;
  control: Control<any>;
  rules?: RegisterOptions;
}

interface IMyDropdown extends ITextField {
  nmb?: any;
  textarea?: boolean;
  rows?: number;
  type?: string;
  label?: string;
  placeholder?: string;
  multiline?: any;
  onClick?: any;
  select?: boolean;
  value?: string;
  options: IDropdownOption[] | any;
  additionalOptions?: IDropdownOption[];
}

//LABEL IS IMPORTANT FOR DEFAULT VALUE IN OUTLINED VARIANT

export const MyDropdown: React.FC<IMyDropdown> = ({ nmb, options, additionalOptions, rules, control, name, error, label, placeholder, onClick, select, value }) => {
  // const elementRef: any = useRef();

  const classes = inputStyles({
    nmb: nmb,
    isAdditionalOptions: Boolean(additionalOptions && additionalOptions.length > 0),
  });

  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => {
          return (
            <TextField
              id="paginateSelect"
              classes={{ root: clsx(classes.textField, classes.dropdown) }}
              select={select}
              placeholder={placeholder}
              variant="outlined"
              // fullWidth
              helperText={error?.message}
              error={Boolean(error)}
              label={value ? value : placeholder}
              {...field}
              onChange={onClick}
              InputProps={{ classes: { input: clsx(classes.dropdown) } }}
            >
              {additionalOptions &&
                additionalOptions?.length > 0 &&
                additionalOptions?.map((option: IDropdownOption) => (
                  <MenuItem
                    selected={option?.name?.toLowerCase() === value?.toLowerCase()}
                    className={classes.additionalMenuItem}
                    disabled={option.disabled}
                    key={option.name}
                    value={option.name}
                  >
                    <Typography> {option.name}</Typography>
                  </MenuItem>
                ))}

              {options?.length > 0 &&
                options?.map((option: IDropdownOption) => (
                  <MenuItem className={classes.options} key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          );
        }}
      />
    </Box>
  );
};
