import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";

interface IPrevRouteBtn {
  prevRoute: string;
}

export const PrevRouteBtn: React.FC<IPrevRouteBtn> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.root} onClick={() => navigate(-1)}>
      back
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: 20,
    top: 10,
    width: 150,
    height: 150,
    color: "white",
    fontSize: 44,
    background: "#FB1111",
    cursor: "pointer",
    zIndex: 10000,
  },
}));
