import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import { DeleteModal, GreyText, MyText } from "./shared";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDelNewsMutation, useGetNewsQuery } from "../api";
import { useModalWindow } from "../hooks";
import _ from "lodash";

export interface INewsItem {
  id: number;
  status: boolean;
  onDelete: () => void;
  headline: string;
  text: string;
  image?: string;
  intro?: string;
  createdDate: string;
  changeStatusAction: (id: number, status: boolean) => void;
  notBorders?: boolean;
}

export const NewsItem: React.FC<INewsItem> = ({ id, status, headline, createdDate, notBorders, changeStatusAction, onDelete }) => {
  const classes = useStyles({ notBorders });
  const navigate = useNavigate();
  const { open, openWindow, closeWindow } = useModalWindow();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={8} className={classes.headline}>
        <MyText text={headline} onClick={() => navigate(`${id}`)} />
      </Grid>

      <Grid item xs={1} className={classes.textRight}>
        <MyText text={moment(new Date(createdDate)).format("DD.MM.YYYY")} onClick={() => navigate(`${id}`)} />
      </Grid>

      <Grid item xs={1} className={classes.textRight}>
        <Status active={status} id={id} changeStatus={changeStatusAction} />
      </Grid>

      <Grid item xs={1}>
        <GreyText
          isPointer={true}
          align="right"
          withUnderline={true}
          text={"delete"}
          onClick={() => {
            openWindow("delete");
          }}
        />
      </Grid>
      <DeleteModal model="news" open={open} closeWindow={closeWindow} onOk={onDelete} />
    </Grid>
  );
};

export interface IStatus {
  active?: boolean;
  id: number;
  changeStatus: (id: number, status: boolean) => void;
}

export const Status: React.FC<IStatus> = ({ active, changeStatus, id }) => {
  const classes = useStyles({ notBorders: false });
  return (
    <Typography onClick={() => changeStatus(id, !active)} className={clsx(active ? classes.status : classes.statusInActive)}>
      {active ? "active" : "inactive"}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { notBorders?: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: props.notBorders ? "11px 0" : "21px 0",
    borderTop: props.notBorders ? "" : `1px solid ${theme.palette.text.secondary}`,
    "&:last-child": {
      borderBottom: props.notBorders ? "" : `1px solid ${theme.palette.text.secondary}`,
    },
  }),
  status: {
    cursor: "pointer",
    color: theme.palette.success.main,
  },
  statusInActive: {
    cursor: "pointer",
    color: theme.palette.error.main,
  },
  headline: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textRight: {
    textAlign: "right",
  },
}));
