import React, { useState, useEffect } from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { IDropdownOption } from "interfaces/IDropdownOption";
import Select from "react-select";
import _ from "lodash";

interface IDropdown {
  name: string;
  error?: any;

  rules?: RegisterOptions;
  nmb?: any;
  textarea?: boolean;
  rows?: number;
  type?: string;
  label?: string;
  placeholder?: string;
  multiline?: any;
  onClick?: any;
  select?: boolean;
  value?: string;
  options: IDropdownOption[] | any;
  additionalOptions?: IDropdownOption[];
  goNext?: any;
}

export const StartDropdown: React.FC<IDropdown> = ({ options, error, placeholder, onClick, value, goNext }) => {
  const [cashOptions, setCashOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const linkName = [{ value: 101, label: "EXTERNAL_LINK" }];

  const [items, setItems] = useState<any>(linkName);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (!_.isEqual(options, cashOptions)) {
      const itemArr = options.map((option: IDropdownOption) => {
        return { value: option.id, label: option.name };
      });
      setItems([...items, ...itemArr]);
      setCashOptions(options);
    }
  }, [options]);

  const onHandChange = (e: any) => {
    setSelected(e);
    onClick(e);
  };

  return (
    <>
      <Select
        options={items}
        onMenuScrollToBottom={() => goNext()}
        placeholder={placeholder ? selected : placeholder}
        aria-errormessage={error}
        closeMenuOnSelect={true}
        onChange={(e) => onHandChange(e)}
        isSearchable={true}
        value={selected ? selected : value}
      />
    </>
  );
};
