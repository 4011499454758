import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

interface IGreyText {
  text: string;
  withUnderline?: boolean;
  onClick?: () => void;
  align?: "center" | "inherit" | "justify" | "left" | "right";
  isPointer?: boolean;
}

export const GreyText: React.FC<IGreyText> = ({ text, withUnderline, align, onClick, isPointer }) => {
  const classes = useStyles();
  return (
    <Typography
      style={{ cursor: isPointer ? "pointer" : "initial" }}
      onClick={onClick}
      align={align ? align : "left"}
      className={withUnderline ? classes.withUnderline : ""}
      variant="subtitle2"
    >
      {text}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  withUnderline: {
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));
