import { Box, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../redux/hooks";
import { useLocation } from "react-router-dom";
import { getFromLocalStorage } from "utils";
import { ITokens } from "models";
import { Nordea, Rio } from "components/Logo";

export const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const auth = useAppSelector(({ auth }) => auth);
  useEffect(() => {
    const tokens = getFromLocalStorage("tokens") as ITokens;
    if (tokens?.accessToken && !pathname.includes("dashboard")) {
      navigate("/dashboard/start");
    } else if (!tokens?.accessToken) {
      navigate("/");
    }
  }, [auth.tokens, auth]);

  return (
    <Box className={classes.root}>
      <Rio />
      <Nordea />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    paddingLeft: 100,
    paddingRight: 100,
    background: theme.palette.primary.main,
    color: "white",
    textTransform: "uppercase",
    fontWeight: 800,
    fontFamily: "Inter",
  },
  typography: {
    fontFamily: "Fredoka One",
    textTransform: "lowercase",
  },
}));
