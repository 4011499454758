export const SERVER_API_PROD = process.env.REACT_APP_SERVER_API_PROD;
export const SERVER_API_DEV = process.env.REACT_APP_SERVER_API_DEV;

const localhost = !process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === "development";

const api: string | undefined = localhost ? SERVER_API_DEV : SERVER_API_PROD;

export const API_URLS = {
  login: api + "auth/login",
  prices: api + "prices/",
  palettes: api + "palettes/",
  countries: api + "countries/",
  news: api + "news/",
  tags: api + "tags/",
  pages: api + "pages/",
  contacts: api + "contacts/",
  events: api + "events",
  push: api + "push",
  base: api,
};
