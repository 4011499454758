import { IDropdownOption } from "interfaces/IDropdownOption";

export const ESG_PAGE_DROPDOWN_VALUES = {
  EXTERNAL_LINK: "EXTERNAL_LINK",
  NEWS: "NEWS",
};
export const ESG_PAGE_DROPDOWN_OPTIONS = [
  { name: ESG_PAGE_DROPDOWN_VALUES.EXTERNAL_LINK },
  {
    name: ESG_PAGE_DROPDOWN_VALUES.NEWS,
    disabled: true,
  },
] as IDropdownOption[];
