import React from "react";
import { useRoutes } from "react-router-dom";
import DateAdapter from "@mui/lab/AdapterMoment";
import { LocalizationProvider } from "@mui/lab";
import { Helmet } from "react-helmet";
import { Header } from "./layouts/Header";
import { MyToaster } from "./components/Toaster";

import { useMyRoutes } from "./hooks/useMyRoutes";

const App = function () {
  const routes = useMyRoutes();
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Header />
      <MyToaster />
      {useRoutes(routes)}
      <Helmet>
        <title>rio mobile</title>
      </Helmet>
    </LocalizationProvider>
  );
};

export default App;
