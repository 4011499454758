import { toast, ToastBar, Toaster } from "react-hot-toast";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

export const MyToaster = () => {
  return (
    <Toaster position="top-center">
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <IconButton onClick={() => toast.dismiss(t.id)}>
                  <ClearIcon />
                </IconButton>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
