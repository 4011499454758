import React, { useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FieldError } from "react-hook-form";

interface ITextEditor {
  onChange: (func: string) => void;
  value: string | null | undefined;
  error?: FieldError | null | undefined;
}

const TextEditor = ({ onChange, value, error }: ITextEditor) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  function toHtml(editorState: EditorState) {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }

  useEffect(() => {
    if (toHtml(editorState) === value) return;
    const defaultValue = value ? value : "";
    const blocksFromHtml = htmlToDraft(defaultValue);
    const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  }, [value]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const html = toHtml(editorState);
    if (value !== html) {
      onChange(html);
    }
  };

  return (
    <div className="editor">
      <Editor editorStyle={styleBody} spellCheck editorState={editorState} onEditorStateChange={onEditorStateChange} />
      {error ? <h2 style={{ color: "red" }}>{error?.message}</h2> : ""}
    </div>
  );
};

const styleBody = {
  border: "1px solid #d3d3d3",
  borderRadius: "5px",
  backgroundColor: "#F8F9FB",
  maxHeight: "400px",
  paddingLeft: "15px",
  margin: "20px 0px",
};

export default TextEditor;
