import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHandleFile } from "hooks";
import { useGetTagsQuery, useGetOneNewsQuery, useUpdateNewsMutation, useGetTagsByNewsIdQuery } from "api";
import { MyButton, MyDropdown, MyFilePicker, MyInput, Preloader, Title } from "components";
import { MyTags } from "components/shared/Inputs/MyTags";
import { inputStyles } from "components/shared/Inputs/styles";
import { checkFileExist } from "utils/checkFileExist";
import { NewsConst } from "constants/news_type";
import { IDropdownOption } from "interfaces/IDropdownOption";
import { updateNewsById } from "dto/news/updateNewsById.dto";
import { urlValidation } from "components/shared/Inputs/validations";
import { IMAGE } from "constants/imagesConst";
import TextEditor from "components/TextEditor";
import { ITag } from "models/ITag";
import { MyMP3Picker } from "components/MyMP3Picker";
import { useHandleMP3 } from "hooks/useHandleMP3";

export const NewsDetailsById = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<updateNewsById>({ mode: "onChange" });

  const fields = watch();

  const { id } = useParams();
  const navigate = useNavigate();

  const [currentTags, setCurrentTags] = useState<IDropdownOption[] | []>([]);
  const [newsType, setNewsType] = useState<any>();
  const [imageFromServer, setImageFromServer] = useState<any>("");
  const [fileFromServer, setFileFromServer] = useState<any>("");

  const { data: currentNews, isLoading: isGettingCurrentNews } = useGetOneNewsQuery(Number(id));
  const { data: tags, isLoading: isGettingTags } = useGetTagsQuery();
  const [editCurrentNews, { isLoading: isEditingCurrentNews }] = useUpdateNewsMutation();

  const { file: managerImage, handleChangeFile: handleChangeManagerImage } = useHandleFile();
  const { file: managerMP3, handleChangeFile: handleChangeManagerMP3 } = useHandleMP3();

  const classes = inputStyles({
    nmb: 0,
    multiline: false,
  });

  useEffect(() => {
    if (currentNews) {
      const arr: ITag[] = [];
      const onlyTags = currentNews.news_to_tags.filter((item) => arr.push(item.tag));
      setCurrentTags(arr);
    }
  }, [currentNews]);

  useEffect(() => {
    if (currentNews) {
      setNewsType(currentNews.newstype);
      const { headline, newstype, text, link } = currentNews;
      setValue("headline", headline);
      setValue("newstype", newstype);
      setValue("text", text);
      setValue("link", link);
      setValue("tags", currentTags);
      if (currentNews.image) {
        handleChangeManagerImage(currentNews.image);
        setImageFromServer(currentNews.image);
      }
      if (currentNews.file) {
        handleChangeManagerMP3(currentNews.file);
        setFileFromServer(currentNews.file);
      }
    }
  }, [currentNews]);

  const handleSelectTags = (newTag: IDropdownOption | null, isDelete?: boolean, isClear?: boolean): void => {
    if (isClear) {
      setCurrentTags([]);
    } else {
      if (newTag) {
        setCurrentTags((prev): IDropdownOption[] => {
          if (isDelete) {
            return prev.filter((i) => i.name !== newTag.name);
          } else {
            return [newTag, ...prev];
          }
        });
      }
    }
  };

  const handleEnterTags = (newTag: IDropdownOption): void => {
    setCurrentTags((prev) => [...prev, newTag]);
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    if (currentNews?.id) {
      for (const prop in data) {
        if (data[prop]) {
          formData.append(prop, data[prop]);
        }
      }
      if (!data.link) {
        formData.set("link", "");
      } else {
        formData.set("link", data.link.replaceAll(" ", ""));
      }
      if (!data.text) {
        formData.set("text", "");
      }

      formData.set("imageFromServer", imageFromServer);
      formData.set("fileFromServer", fileFromServer);

      checkFileExist(managerImage, data.image, formData);
      formData.append("id", String(currentNews.id));
      if (tags) {
        formData.set(
          "tags",
          JSON.stringify(
            currentTags.map((ct) => {
              if (ct.isNew === true) {
                const tag = tags?.find((tag) => tag.name === ct.name);
                if (tag) {
                  return {
                    name: ct.name,
                    id: tag.id,
                    isNew: false,
                  };
                } else {
                  return {
                    name: ct.name,
                    id: -1,
                    isNew: true,
                  };
                }
              } else {
                return {
                  name: ct.name,
                  id: ct.id,
                  isNew: false,
                };
              }
            }),
          ),
        );
      }

      editCurrentNews(formData).then(() => {
        handleChangeManagerImage(null);
        handleChangeManagerMP3(null);
        navigate(`/dashboard/news`);
      });
    }
  };

  return (
    <>
      <Grid container>
        <Box mb={8}>
          <Title text="News Details" />
        </Box>
      </Grid>
      <form
        style={{
          display: "flex",
          gap: "100px",
          width: "max-width",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={11} md={8} lg={5} xl={4}>
          <Box mb={8}>
            <MyDropdown
              options={[
                {
                  name: "Video",
                  id: 1,
                },
                {
                  name: "Podcast",
                  id: 2,
                },
                {
                  name: "Insight",
                  id: 3,
                },
              ]}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewsType(e.target.value);
                setValue("newstype", e.target.value);
              }}
              select={true}
              label="News Type"
              nmb={true}
              placeholder="-Type-"
              control={control}
              name="newstype"
              rules={{ required: false }}
              error={errors.newstype}
              value={fields.newstype}
            />
          </Box>
          {newsType === NewsConst.INSIGHT && (
            <>
              <Box mb={8}>
                <MyInput label="Headline" nmb={true} placeholder="Headline" control={control} name="headline" rules={{ required: "Headline is required" }} error={errors.headline} />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>Text</Typography>
              <Box mb={8}>
                <Controller
                  rules={{ required: "News text is required" }}
                  render={({ field }) => <TextEditor {...field} error={errors.text} />}
                  name="text"
                  control={control}
                  defaultValue=""
                />
              </Box>
              <Box mb={8}>
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </>
          )}
          {newsType === NewsConst.VIDEO && (
            <>
              <Box mb={8}>
                <MyInput label="Headline" nmb={true} placeholder="Headline" control={control} name="headline" error={errors.headline} />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>Text</Typography>
              <Box mb={8}>
                <Controller render={({ field }) => <TextEditor {...field} />} name="text" control={control} defaultValue="" />
              </Box>

              <Box mb={8}>
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box mb={8}>
                  <MyInput name="link" label="Video Link" rules={urlValidation(true)} nmb={true} control={control} error={errors.link} placeholder="URL" />
                </Box>
              </div>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </>
          )}
          {newsType === NewsConst.PODCAST && (
            <>
              <Box mb={8}>
                <MyInput label="Headline" nmb={true} placeholder="Headline" control={control} name="headline" rules={{ required: "Headline is required" }} error={errors.headline} />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>Text</Typography>
              <Box mb={8}>
                <Controller
                  rules={{ required: "News text is required" }}
                  render={({ field }) => <TextEditor {...field} error={errors.text} />}
                  name="text"
                  control={control}
                  defaultValue=""
                />
              </Box>

              <Box mb={8}>
                <MyFilePicker file={managerImage} setFile={handleChangeManagerImage} size={IMAGE.OTHER_SIZE} />
              </Box>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box mb={8}>
                  {/* <MyInput label="Audio Link" rules={urlValidation(true)} nmb={true} control={control} name="link" error={errors.link} placeholder="URL" /> */}
                  <MyMP3Picker file={managerMP3} setFile={handleChangeManagerMP3} />
                </Box>
              </div>
              <Box mb={8}>
                <MyButton text="Save" type="submit" />
              </Box>
            </>
          )}
        </Grid>

        <Box
          style={{
            position: "relative",
            maxWidth: "400px",
            minWidth: "300px",
          }}
        >
          <Typography className={classes.label}>Tags</Typography>
          <MyTags
            updateValue={setValue}
            handleSelectTags={handleSelectTags}
            handleEnterTags={handleEnterTags}
            name="tags"
            control={control}
            currentTags={currentTags ? currentTags : []}
            options={tags ? tags : []}
          />
        </Box>
      </form>
      <Preloader isLoading={isGettingTags || isGettingCurrentNews || isEditingCurrentNews} />
    </>
  );
};
